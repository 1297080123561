import React, { useEffect, useState } from "react";
import { Breadcrumb, Layout, Form, Input, Upload, Button, Image, Select , Row ,Col } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import { errorResponse, successResponse,validateMessages } from "../../../helpers/response";
import url from "../../../../Development.json";
import { UploadOutlined } from "@ant-design/icons";
import thumb from '../../../../assets/images/thumb.jpg'
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import ImgCrop from 'antd-img-crop';
import { Modal } from "react-bootstrap";
import { Card, CardHeader } from "reactstrap";


const PageForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [id, setId] = useState('');
    const [investorRole, setInvestorRole] = useState("");
    const [investorTypeArray, setInvestorTypeArray] = useState([]);
    const [investorType, setInvestorType] = useState('');
    const [proxyModal, setProxyModal] = useState(false);
    const [address, setAddress] = useState("");
    const [autoComplete, setAutoComplete] = useState(null);
    const [uploadImage, setUploadImage] = useState(thumb);
    const { Option } = Select;
    const { state } = useLocation()
    const { Content } = Layout;

    const onAutoCompleteIsLoad = (inputAutoComplete) => {
        setAutoComplete(inputAutoComplete);
    };
    const onAutoCompletePlaceIsChanged = () => {
        if (autoComplete !== null) {
            setAddress(autoComplete.getPlace().formatted_address);
        }
        else {
            console.log("Autocomplete is not loaded yet");
        }
    };

    const handleInvestorTypeChange = (value) => {
        setInvestorType(value);
        form.setFieldsValue({
            investor_type: value,
        });
    };

    const onChangeImage = async (info) => {
        if (info.file.status === 'done') {
            setUploadImage(info.file.response.data.image_url);
        }
    };

    useEffect(() => {
        if (state && state.id) {
            fetchData(state.id)
            setId(state.id);
            fetchinvestorData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const fetchinvestorData = async () => {
        setIsLoading(true);
        await Http
            .post(process.env.REACT_APP_BASE_URL + url.investor_type)
            .then((response) => {
                let data = response.data.data;
                setInvestorTypeArray(data)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    }
    const fetchData = (id) => {
        let idpass = `?id=${id}`;
        Http
            .get(process.env.REACT_APP_BASE_URL + url.user_edit_get + idpass)
            .then((response) => {
                let data = response.data.data;
                console.log(data)
                form.setFieldsValue({
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    bio: data.bio,
                    address: data.address,
                    mobile_number: data.mobile_number,
                    investor_type: data.investor_type,
                });
                setAddress(data.address);
                setInvestorRole(data.investor_role)
                setInvestorType(data.investor_type);
                if (data.profile_photo) {
                    setUploadImage(data.profile_photo)
                }
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onSubmit = async (data) => {
        setIsLoading(true);
        if (state && state.id) {
            data["id"] = state.id;
        }
        await Http
            .post(
                process.env.REACT_APP_BASE_URL + url.user_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
                navigate('/users');
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onFinish = (values) => {
        const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            mobile_number: values.mobile_number,
            bio: values.bio,
            investor_type: investorType,
            investor_role: investorRole,
            profile_photo: uploadImage,
            address: address,
        }
        onSubmit(data);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onPreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);

                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>User</Breadcrumb.Item>
                <Breadcrumb.Item> {id ? 'Update' : 'Add'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper">
                <Card title="User Form" bordered={true} className=''>
                    <CardHeader className="card-header-part">
                        <h5>User {id ? 'Update' : 'Add'}</h5>
                    </CardHeader>

                    <Form form={form}
                        name="basic"
                        labelCol={{
                            span: 3,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        validateMessages={validateMessages()}
                        autoComplete="off"
                        className="m-5"
                    >
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            id="first_name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            id="last_name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            id="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Mobile Number"
                            name="mobile_number"
                            id="mobile_number"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 7,
                                    max: 15,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Bio"
                            name="bio"
                            id="bio"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Address"
                            name="address"
                            id="address"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                libraries={["drawing", "places"]}
                            >
                                <Autocomplete
                                    onLoad={onAutoCompleteIsLoad}
                                    onPlaceChanged={onAutoCompletePlaceIsChanged}
                                >
                                    <Input value={address} />
                                </Autocomplete>
                            </LoadScript>
                        </Form.Item>

                        <Form.Item
                            label="Investor Type"
                            name="investor_type"
                            id="investor_type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            {console.log(investorType)}
                            <Select
                                value={investorType}
                                onChange={(e) => handleInvestorTypeChange(e)}
                            >
                                <Option selected disabled> Select </Option>
                                {
                                    investorTypeArray.map((item, i) => {
                                        return <Option key={i} value={item._id}> {` ${item.name}  `} </Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Profile Photo"
                            name="ppf"
                            id="ppf"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >

                            <Row>
                                <Col span={12}>
                                    <ImgCrop>
                                        <Upload
                                            name='files'
                                            action={process.env.REACT_APP_BASE_URL + url.image_upload + '?type=1'}
                                            listType="picture"
                                            onChange={onChangeImage}
                                            onPreview={onPreview}
                                        >
                                            <Button
                                                className=""
                                                icon={<UploadOutlined />}>
                                                upload
                                            </Button>

                                        </Upload>
                                    </ImgCrop>
                                </Col>
                                <Col span={12}>
                                    <Image
                                        src={uploadImage}
                                        alt=""
                                        className="rounded-circle"
                                        height={"150px"}
                                        width={"150px"}
                                    /></Col>
                            </Row>



                        </Form.Item>


                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>
            {/* =========== choose your proxy =========== */}
            <Modal
                show={proxyModal}
                onHide={() => setProxyModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="claimModal"
            >
                <Modal.Header className="p-0 pt-1 mb-2" closeButton>
                    <div className="py-2 ps-4">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Choose Your Proxy
                        </Modal.Title>
                    </div>
                </Modal.Header>
                {/* <Form className="claimForm">
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 mx-auto">
                                <Form.Group className="mb-4 inputOuter_">
                                    <Form.Label className="defLabel_">
                                        Search Active Investor{" "}
                                        <span className="requireStar">*</span>
                                    </Form.Label>
                                    <Input
                                        onChange={handleSerchInvestorChange}
                                        defaultValue={serchActiveInvestor}

                                    />

                                    <div className="m-3 search-investor-scroll">
                                        <Accordion defaultActiveKey="0">
                                            {
                                                activeInvestorArray.map((item, i) => {
                                                    let toShowBio = '';
                                                    if (item.bio) {
                                                        const content = item.bio
                                                        toShowBio = content.substring(0, 70) + "...";
                                                    }


                                                    return <div className="col-md-12 col-sm-12 mx-auto" key={i}>
                                                        <Accordion.Item eventKey={i} className="accordion-item-cutum"
                                                            onClick={(e) => handleSelectActiveInvestorChange(item._id)}>
                                                            <ContextAwareToggle eventKey={i}>
                                                                <Form.Group className="mb-4">
                                                                    <Form.Check
                                                                        inline
                                                                        label={item.first_name}
                                                                        name="active_investor"
                                                                        type="radio"
                                                                        className="radioInput"
                                                                        checked={(selectActiveInvestor === item._id) ? true : false}
                                                                        onChange={(e) => handleSelectActiveInvestorChange(item._id)}
                                                                    />
                                                                </Form.Group>
                                                            </ContextAwareToggle>
                                                            <Accordion.Collapse eventKey={i}>
                                                                <Card.Body>
                                                                    <div className="col-md-12 col-sm-12 mx-auto">
                                                                        <div className="p-3 mb-4 bgLightBlue investorContent">
                                                                            <p className="mb-2 title">
                                                                                {toShowBio}
                                                                            </p>
                                                                            <div className="d-flex justify-content-between flex-wrap">
                                                                                <div className="px-2 py-1 text-center">
                                                                                    <span className="title d-block pb-3">
                                                                                        My Liquidity Fund
                                                                                    </span>
                                                                                    <span className="content">£10000</span>
                                                                                </div>
                                                                                <div className="px-2 py-1 text-center">
                                                                                    <span className="title d-block pb-3">
                                                                                        Membership Invested
                                                                                    </span>
                                                                                    <span className="content">£5000</span>
                                                                                </div>
                                                                                <div className="px-2 py-1 text-center">
                                                                                    <span className="title d-block pb-3">
                                                                                        Personal Invested
                                                                                    </span>
                                                                                    <span className="content">£5000</span>
                                                                                </div>
                                                                                <div className="px-2 py-1 text-center">
                                                                                    <span className="title d-block pb-3">P&L</span>
                                                                                    <span className="text-success content">
                                                                                        +£2000 (+20%)
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Accordion.Item>
                                                    </div>
                                                })
                                            }
                                        </Accordion>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="">
                        <button
                            onClick={() => setProxyModal(false)}
                            type="button"
                            className="btn_White"
                        >
                            Cancel
                        </button>

                        <Button type="button" onClick={(e) => activeInvestorSetup()} className='btn_Black saveBtn'>
                            {isLoading ? <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                            </>
                                : ''
                            }
                            {isLoading ? 'loading...' : 'Save'}
                        </Button>
                    </Modal.Footer>
                </Form> */}
            </Modal>
            {/* ==================== */}
        </Content>
    )
};

export default PageForm;
