import RoutePage from './component/routes/index';
import { ToastContainer } from 'react-toastify';
import './App.css';
import 'antd/dist/antd.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Fragment } from 'react';

const App = () => {
    return (
        <Fragment>
            <RoutePage />
            <ToastContainer />
        </Fragment>
    );
}

export default App;
