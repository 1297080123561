import { toast } from 'react-toastify';

function isLoginResponse(navigate) {
    const isLogin = localStorage.getItem("accessToken") || false;
    if (isLogin === null) {
        navigate('/login');
    }
    if (isLogin === false) {
        navigate('/login');
    }
}
function insertAfter(referenceNode, newNode) {
    let parentthis =  referenceNode.parentNode.closest(".form-group");
    // referenceNode.parentNode.closest(".form-control").classList.add("is-invalid");
    // parentthis.insertBefore(newNode, referenceNode.nextSibling);
    parentthis.insertBefore(newNode, parentthis.nextSibling);
}

function isErrorMessage(name, message) {
    var el = document.createElement("span");
    el.classList.add("error-message");
    el.innerHTML = humanize(name) + ' ' + message;

    var div = document.getElementById(name);
    if (div) {
        if (div.nextSibling != null) {
            div.nextSibling.remove()
        }
        insertAfter(div, el);
    }
}
// function isErrorMessage(name, message) {
//     var el = document.createElement("span");
//     el.classList.add("error-message");
//     el.innerHTML = humanize(name) + ' ' + message;



//     var div = document.getElementById(name);
//     var elem = document.querySelectorAll('[name='+name+']');
//     elem.forEach(function(parentdiv){
//         if (parentdiv) {
//             if (div.nextSibling != null) {
//                 div.nextSibling.remove()
//             }

//             if(parentdiv.closest('.form-group')){
//                 // div.closest(".form-control").classList.add("is-invalid");
//                 parentdiv.closest('.form-group').classList.add("is-invalid");
//             }
//             insertAfter(parentdiv, el);
//         }
//     });


//     // var div = document.getElementById(name);

//     // if (div) {
//     //     // if (div.nextSibling != null) {
//     //     //     div.nextSibling.remove()
//     //     // }
//     //     if(div.parentNode.closest('.form-group')){
//     //         // div.closest(".form-control").classList.add("is-invalid");
//     //         div.parentNode.closest('.form-group').classList.add("is-invalid");
//     //     }
//     //     insertAfter(div, el);
//     // }
// }
function humanize(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}
function isError(error) {
    var errors_entries = Object.entries(error);
    if (errors_entries) {
        var allDivsCollections = document.getElementsByClassName('error-message');
        var arr = Array.from(allDivsCollections);
        arr.forEach((singleElement) => {
            singleElement.parentNode.closest('.form-group').classList.remove("is-invalid");
            singleElement.remove();
        })
        errors_entries.forEach((errorAll) => {
            let type = errorAll[1].type;
            // let name = errorAll[1].ref.name;
            let name = errorAll[0];

            let message = '';
            if (type === 'required') {
                message = 'is required.';
                isErrorMessage(name, message);
            }
            // else if (type === 'minLength') {
            //     message =  errorAll[1].message;
            //     isErrorMessage(name, message);
            // } else if (type === 'maxLength') {
            //     message = 'Max length exceeded';
            //     isErrorMessage(name, message);
            // } else if (type === 'validate') {
            //     message = errorAll[1].message;
            //     isErrorMessage(name, message);
            // } else if (type === 'min') {
            //     message = 'min number is 0';
            //     isErrorMessage(name, message);
            // } else if (type === 'max') {
            //     message = 'max number is 100';
            //     isErrorMessage(name, message);
            // } else if (type === 'validate') {
            //     message = errorAll[1].message;
            //     isErrorMessage(name, message);

            // } else if (type === 'pattern') {
            //     message = errorAll[1].message;
            //     isErrorMessage(name, message);

            // }
            else {
                message = errorAll[1].message;
                isErrorMessage(name, message);
            }
        });
    }

}
function validateMessages() {
     /* eslint-disable no-template-curly-in-string */

 const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
/* eslint-enable no-template-curly-in-string */

    return validateMessages;
}
function configHeaderAxios() {
    const config = {
        headers: {
            'content-type': 'application/json',
            'authorization': localStorage.getItem('accessToken')
        }
    }
    return config;
}
function errorResponse(error) {

    if (error.response.status === 422) {
        let errorData = error.response.data;
        if (errorData) {
            var errors = Object.values(errorData);
            if (errors) {
                errors.forEach((err) => {
                    toast(`${err} !`);
                });
            }
        }
    }
    if (error.response.status === 401) {
        let errorData = error.response.data.Unauthorized;
        localStorage.clear();
        toast(`${errorData} !`);
        window.location.reload();
    }
  
}

function successResponse(response) {
    if (response.status === 200) {
        if (response.data.message) {
            toast.success(`${response.data.message} !`, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
}

export { errorResponse, successResponse, isLoginResponse, configHeaderAxios, isError ,validateMessages };