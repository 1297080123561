import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Layout, Form, Input, Upload, Button,Image } from "antd";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import { errorResponse, successResponse,validateMessages } from "../../../helpers/response";
import url from "../../../../Development.json";
import { Card, CardHeader } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { UploadOutlined } from "@ant-design/icons";


const PageForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [id, setId] = useState('');
    const [uploadImage, setUploadImage] = useState([]);
    const [description, setDescription] = useState('<p>Product <strong>Abc &nbsp;</strong></p>');
    const [clubId, setClubId] = useState('');
    const { clubIds } = useParams();

    const { state } = useLocation()
    const { Content } = Layout;
    const menubar = true;

    const plugins =
        "link image code table textcolor colorpicker fullscreen hr lists";
    const toolbar =
        "fontselect fontsizeselect formatselect | " +
        "bold italic underline strikethrough subscript superscript | " +
        "blockquote removeformat | forecolor backcolor | " +
        "alignleft aligncenter alignright alignjustify | " +
        "indent outdent | numlist bullist | " +
        "link unlink | hr table image | fullscreen code | undo redo";
    const editorRef = useRef(null);

    const onChangeImage = async (info) => {
        if (info.file.status === 'done') {
            setUploadImage([...uploadImage, info.file.response.data.image_url])

        }
    };
    useEffect(() => {
        async function getDataAsync() {
            if (clubIds) {
                await setClubId(clubIds);
            }
            if (state && state.id) {
                await fetchData(state.id)
                await setId(state.id);
            }
        }
        getDataAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubIds]);



    const fetchData = (id) => {
        let idpass = `?id=${id}`;
        Http
            .get(process.env.REACT_APP_BASE_URL + url.club_product_edit_get + idpass)
            .then((response) => {
                let data = response.data.data;
                form.setFieldsValue({
                    name: data.name,
                    price: data.price,
                    description: data.description,
                    coin: data.coin,
                    old_price: data.old_price,
                    type: data.type,
                });
                setDescription(data.description);
                setUploadImage(JSON.parse(data.images));
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onSubmit = async (data) => {
        setIsLoading(true);
        if (state && state.id) {
            data["id"] = state.id;
        }
        await Http
            .post(
                process.env.REACT_APP_BASE_URL + url.club_product_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
                navigate('/club-product/' + clubId);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onFinish = (values) => {
        const data = {
            name: values.name,
            price: values.price,
            old_price: values.old_price,
            coin: values.coin,
            type: 'free',
            description: description,
            club_id: clubId,
            images: uploadImage,
        }
        onSubmit(data);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleEditorChangeDescription = (value) => {
        setDescription(value);
    };
    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Club Product</Breadcrumb.Item>
                <Breadcrumb.Item> {id ? 'Update' : 'Add'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper">
                <Card title="Club Form" bordered={true} className=''>
                    <CardHeader className="card-header-part">
                        <h5>Club Product {id ? 'Update' : 'Add'}</h5>
                    </CardHeader>

                    <Form form={form}
                        name="basic"
                        labelCol={{
                            span: 3,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        validateMessages={validateMessages()}
                        autoComplete="off"
                        className="m-5"
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            id="name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Price"
                            name="price"
                            id="price"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 0,
                                    max: 999999999999999,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Old Price"
                            name="old_price"
                            id="old_price"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 0,
                                    max: 999999999999999,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Coin"
                            name="coin"
                            id="coin"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 0,
                                    max: 50000,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item
                            label="Description"
                            name="description"
                            id="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMAC_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={description}
                                init={{ plugins, toolbar, menubar }}
                                onEditorChange={handleEditorChangeDescription}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Images"
                            name="image"
                            id="image"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >

                            <Upload
                                name='files'
                                action={process.env.REACT_APP_BASE_URL + url.image_upload + '?type=3'}
                                listType="picture"
                                maxCount={1}
                                multiple
                                onChange={onChangeImage}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload>
                            {uploadImage.map((image, i) =>
                                <Image
                                    width={70}
                                    height={70}
                                    className="p-1"
                                    src={image}
                                    key={i}
                                />
                            )}

                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>

        </Content>
    )
};

export default PageForm;
