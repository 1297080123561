import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Layout, Form, Input, Select, DatePicker } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import { errorResponse, successResponse , validateMessages } from "../../../helpers/response";
import url from "../../../../Development.json";
import { Editor } from "@tinymce/tinymce-react";
import moment from 'moment';

const PageForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [id, setId] = useState('');
    const [investmentTypeArray, setInvestmentTypeArray] = useState([]);
    const [investmentType, setInvestmentType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [description, setDescription] = useState('<p>Product <strong>Abc &nbsp;</strong></p>');

    const { state } = useLocation()
    const { Content } = Layout;

    const menubar = true;

    const plugins =
        "link image code table textcolor colorpicker fullscreen hr lists";

    const toolbar =
        "fontselect fontsizeselect formatselect | " +
        "bold italic underline strikethrough subscript superscript | " +
        "blockquote removeformat | forecolor backcolor | " +
        "alignleft aligncenter alignright alignjustify | " +
        "indent outdent | numlist bullist | " +
        "link unlink | hr table image | fullscreen code | undo redo";


    const editorRef = useRef(null);
    useEffect(() => {
        if (state && state.id) {
            fetchData(state.id)
            setId(state.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = (id) => {
        let idpass = `?id=${id}`;
        Http
            .get(process.env.REACT_APP_BASE_URL + url.proposal_edit_get + idpass)
            .then((response) => {
                let data = response.data.data;
                form.setFieldsValue({
                    title: data.title,
                    type: data.type,
                    profile_photo: data.profile_photo,
                    name: data.name,
                    amount: data.amount,
                    turnover_pa: data.turnover_pa,
                    expected_return_investment: data.expected_return_investment,
                    start_date: data.start_date,
                    end_date: data.end_date,
                    description: data.description,
                    file_upload: data.file_upload,
                });
                setStartDate(data.start_date)
                setEndDate(data.end_date)
                setDescription(data.description)
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        if (state && state.id) {
            data["id"] = state.id;
        }
        Http
            .post(
                process.env.REACT_APP_BASE_URL + url.proposal_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
                navigate('/proposal');
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onFinish = (values) => {
        const data = {
            title: values.title,
            type: values.type,
            profile_photo: values.profile_photo,
            name: values.name,
            amount: values.amount,
            turnover_pa: values.turnover_pa,
            expected_return_investment: values.expected_return_investment,
            start_date: startDate,
            end_date: endDate,
            description: description,
        }
        onSubmit(data);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const fetchInvestmentData = async () => {
        setIsLoading(true);
        await Http
            .post(process.env.REACT_APP_BASE_URL + url.investment_type)
            .then((response) => {
                let data = response.data.data;
                setInvestmentTypeArray(data)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    }
    useEffect(() => {
        async function getData() {
            await fetchInvestmentData()
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleInvestmentTypeChange = (e) => {
        setInvestmentType(e.target.value);
    };
    const onChangeStartDate = (value, dateString) => {
        setStartDate(dateString);
    };
    const onChangeEndDate = (value, dateString) => {
        setEndDate(dateString);
    };
    const onOkStartDate = (value) => {
        // console.log('onOk: ', value);
    };
    const onOkEndDate = (value) => {
        // console.log('onOk: ', value);
    };
    const handleEditorChangeDescription = (value) => {
        setDescription(value);
    };
    const dateFormat = 'YYYY-MM-DD HH:MM:SS';
    // 2022-09-23 13:58:56

    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Proposal</Breadcrumb.Item>
                <Breadcrumb.Item> {id ? 'Update' : 'Add'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper">
                <Card title="Proposal Form" bordered={true}>
                    <Form form={form}
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        validateMessages={validateMessages()}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            id="title"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Type"
                            name="type"
                            id="type"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={investmentType}
                                onChange={(e) => handleInvestmentTypeChange(e)}
                            >
                                <option selected value={""} disabled> Select </option>
                                {
                                    investmentTypeArray.map((item, i) => {
                                        return <option key={i} value={item.id}> {` ${item.name}  `} </option>;
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Company Valuation"
                            name="name"
                            id="name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Amount"
                            name="amount"
                            id="amount"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Turnover Pa"
                            name="turnover_pa"
                            id="turnover_pa"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Expected Return Investment"
                            name="expected_return_investment"
                            id="expected_return_investment"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Start Date"
                            name="start_date_show"
                            id="start_date_show"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            {startDate}
                            <DatePicker
                                className="w-100"
                                showTime
                                defaultValue={moment(startDate, dateFormat)}
                                format={dateFormat}
                                onChange={onChangeStartDate}
                                onOk={onOkStartDate}
                            />

                        </Form.Item>
                        

                        <Form.Item
                            label="End Date"
                            name="end_date_show"
                            id="end_date_show"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker
                                className="w-100"
                                showTime
                                defaultValue={moment(startDate, dateFormat)}
                                format={dateFormat}
                                onChange={onChangeEndDate}
                                onOk={onOkEndDate}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            id="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMAC_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={description}
                                init={{ plugins, toolbar, menubar }}
                                onEditorChange={handleEditorChangeDescription}
                            />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </Content>
    )
};

export default PageForm;
