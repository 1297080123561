import ErrorPage from "../pages/view/ErrorPage";
import Logout from "../pages/view/Logout";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/view/Login";
import ChangePassword from "../pages/view/ChangePassword";
import Profile from "../pages/view/Profile";

import User from "../pages/view/user/Index";
import UserForm from "../pages/view/user/PageForm";

import Proposal from "../pages/view/proposal/Index";
import ProposalForm from "../pages/view/proposal/PageForm";

import Faq from "../pages/view/faq/Index";
import FaqForm from "../pages/view/faq/PageForm";

import Role from "../pages/view/roles/Index";
import RoleForm from "../pages/view/roles/PageForm";
import Setting from "../pages/view/setting/PageForm";

import Club from "../pages/view/club/Index";
import ClubForm from "../pages/view/club/PageForm";

import ClubProduct from "../pages/view/clubProduct/Index";
import ClubProductForm from "../pages/view/clubProduct/PageForm";

import ClubEvent from "../pages/view/clubEvent/Index";
import ClubEventForm from "../pages/view/clubEvent/PageForm";

import ClubRating from "../pages/view/clubRating/Index";
import ClubRatingForm from "../pages/view/clubRating/PageForm";

import Job from "../pages/view/job/Index";
import JobForm from "../pages/view/job/PageForm";

import AvailableProfile from "../pages/view/profile/Index";
import AvailableProfileForm from "../pages/view/profile/PageForm";

import Notification from "../pages/view/notification/Index";
const routes = [

    {
        path: "/profile",
        exact: true,
        auth: true,
        component: <AvailableProfile title="Profile" />
    },
    {
        path: "/profile/form",
        exact: true,
        auth: true,
        component: <AvailableProfileForm title="Profile Form" />
    },
    {
        path: "/notification",
        exact: true,
        auth: true,
        component: <Notification title="Notification" />
    },
    {
        path: "/job",
        exact: true,
        auth: true,
        component: <Job title="Job" />
    },
    {
        path: "/job/form",
        exact: true,
        auth: true,
        component: <JobForm title="Job Form" />
    },

    {
        path: "/club",
        exact: true,
        auth: true,
        component: <Club title="Club" />
    },
    {
        path: "/club/form",
        exact: true,
        auth: true,
        component: <ClubForm title="Club Form" />
    },

    {
        path: "/club-product/:clubIds",
        exact: true,
        auth: true,
        component: <ClubProduct title="Club Product" />
    },
    {
        path: "/club-product/form/:clubIds",
        exact: true,
        auth: true,
        component: <ClubProductForm title="Club Product Form" />
    },
    {
        path: "/club-event/:clubIds",
        exact: true,
        auth: true,
        component: <ClubEvent title="Club Event" />
    },
    {
        path: "/club-event/form/:clubIds",
        exact: true,
        auth: true,
        component: <ClubEventForm title="Club Event Form" />
    },
    {
        path: "/club-rating/:clubIds",
        exact: true,
        auth: true,
        component: <ClubRating title="Club Rating" />
    },
    {
        path: "/club-rating/form/:clubIds",
        exact: true,
        auth: true,
        component: <ClubRatingForm title="Club Rating Form" />
    },

    {
        path: "/setting",
        exact: true,
        auth: true,
        component: <Setting title="Setting" />
    },
    {
        path: "/role",
        exact: true,
        auth: true,
        component: <Role title="Role" />
    },
    {
        path: "/role/form",
        exact: true,
        auth: true,
        component: <RoleForm title="Role Form" />
    },
    {
        path: "/faq",
        exact: true,
        auth: true,
        component: <Faq title="Faq" />
    },
    {
        path: "/faq/form",
        exact: true,
        auth: true,
        component: <FaqForm title="Faq Form" />
    },

    {
        path: "/users",
        exact: true,
        auth: true,
        component: <User title="Users" />
    },
    {
        path: "/users/form",
        exact: true,
        auth: true,
        component: <UserForm title="Users Form" />
    },
    {
        path: "/proposal",
        exact: true,
        auth: true,
        component: <Proposal title="Proposals" />
    },
    {
        path: "/proposal/form",
        exact: true,
        auth: true,
        component: <ProposalForm title="Proposals Form" />
    },
    {
        path: "/logout",
        exact: true,
        auth: true,
        component: <Logout title="Logout" />
    },
    {
        path: "/profile",
        exact: true,
        auth: true,
        component: <Profile title="Profile" />
    },
    {
        path: "/change-password",
        exact: true,
        auth: true,
        component: <ChangePassword title="Change Password" />
    },
    {
        path: "/dashboard",
        exact: true,
        auth: true,
        component: <Dashboard title="Dashboard" />
    },
    {
        path: "/login",
        exact: true,
        auth: false,
        component: <Login title="login" />
    },
    {
        path: "/",
        exact: true,
        auth: true,
        component: <Login title="Login" />
    },
    {
        path: "*",
        exact: true,
        auth: false,
        component: <ErrorPage title="Error 404" />
    },

]

export default routes;