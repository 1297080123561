import { initializeApp } from 'firebase/app';

// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDKitrYvl3lVwECIPxwfny8PKRB7lXs5OY",
    authDomain: "meridiem-527e2.firebaseapp.com",
    projectId: "meridiem-527e2",
    storageBucket: "meridiem-527e2.appspot.com",
    messagingSenderId: "128435973518",
    appId: "1:128435973518:web:a8407721aa1595c06988df"
};
initializeApp(firebaseConfig);

// const messaging = getMessaging();

let messaging = null;
// messaging = getMessaging();

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payload", payload)
            resolve(payload);
        });
    });

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_MESSAGE_KEY })
        .then((currentToken) => {
            if (currentToken) {
                // console.log('current token for client: ', currentToken);
                localStorage.setItem("fcm_device_token", currentToken);
                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};