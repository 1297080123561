
import React, { useEffect, useState } from "react";
import { Breadcrumb, Layout, Form, Input, Select } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import { errorResponse, successResponse, validateMessages } from "../../../helpers/response";
import url from "../../../../Development.json";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { Card, CardHeader } from "reactstrap";


const PageForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [id, setId] = useState('');
    const [address, setAddress] = useState("");
    const [autoComplete, setAutoComplete] = useState(null);
    const [workPlaceType, setWorkPlaceType] = useState('');
    const [numberOfOpening, setNumberOfOpening] = useState('');
    const [workExperience, setWorkExperience] = useState('');
    const [employmentType, setEmploymentType] = useState('');
    const [description, setDescription] = useState('<p>Product <strong>Abc &nbsp;</strong></p>');
    const { Option } = Select;
    const { state } = useLocation()
    const { Content } = Layout;

    const onAutoCompleteIsLoad = (inputAutoComplete) => {
        setAutoComplete(inputAutoComplete);
    };
    const onAutoCompletePlaceIsChanged = () => {
        if (autoComplete !== null) {
            setAddress(autoComplete.getPlace().formatted_address);
        }
        else {
            console.log("Autocomplete is not loaded yet");
        }
    };

    // const hendleAddressChange = (e) => {
    //     setAddress(e.target.value);
    // };

    useEffect(() => {
        if (state && state.id) {
            fetchData(state.id)
            setId(state.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onFormWorkPlaceTypeChange = (e) => {
        setWorkPlaceType(e.target.value);
    };
    const onFormNumberOfOpeningChange = (e) => {
        setNumberOfOpening(e.target.value);
    };
    const onFormWorkExperienceChange = (e) => {
        setWorkExperience(e.target.value);
    };
    const onFormEmploymentTypeChange = (e) => {
        console.log(e.target.value)
        setEmploymentType(e.target.value);
    };

    const fetchData = (id) => {
        let idpass = `?id=${id}`;
        Http
            .get(process.env.REACT_APP_BASE_URL + url.job_edit_get + idpass)
            .then((response) => {
                let data = response.data.data;
                form.setFieldsValue({
                    title: data.title,
                    company: data.company,
                    email: data.email,
                    job_location: data.job_location,
                    mobile_number: data.mobile_number,
                    workplace_type: data.workplace_type,
                    work_experience: data.work_experience,
                    number_of_opening: data.number_of_opening,
                    employment_type: data.employment_type,
                });
                setAddress(data.address);
                setWorkPlaceType(data.workplace_type);
                setDescription(data.description);
                setWorkExperience(data.work_experience);
                setNumberOfOpening(data.number_of_opening);
                setEmploymentType(data.employment_type);

            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onSubmit = async (data) => {
        setIsLoading(true);
        if (state && state.id) {
            data["id"] = state.id;
        }
        await Http
            .post(
                process.env.REACT_APP_BASE_URL + url.job_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
                navigate('/job');
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onFinish = (values) => {
        const data = {
            title: values.title,
            company: values.company,
            job_location: values.job_location,
            email: values.email,
            mobile_number: values.mobile_number,
            workplace_type: workPlaceType,
            description: description,
            work_experience: workExperience,
            number_of_opening: numberOfOpening,
            employment_type: employmentType,
            address: address,
        }
        console.log(data)
        onSubmit(data);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Job</Breadcrumb.Item>
                <Breadcrumb.Item> {id ? 'Update' : 'Add'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper">
                <Card title="Job Form" bordered={true} className=''>
                    <CardHeader className="card-header-part">
                        <h5>Job {id ? 'Update' : 'Add'}</h5>
                    </CardHeader>

                    <Form form={form}
                        name="basic"
                        labelCol={{
                            span: 3,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        validateMessages={validateMessages()}
                        autoComplete="off"
                        className="m-5"
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            id="title"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Company"
                            name="company"
                            id="company"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Job Location"
                            name="job_location"
                            id="job_location"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Workplace type"
                            name="workplace_type"
                            id="workplace_type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please Select"
                                onChange={onFormWorkPlaceTypeChange}
                                defaultValue={workPlaceType}
                            >
                                <Option value="onsite"> onsite </Option>
                                <Option value="offshore"> offshore </Option>

                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Employment type"
                            name="employment_type"
                            id="employment_type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please Select"
                                onChange={onFormEmploymentTypeChange}
                                defaultValue={employmentType}
                            >
                                <Option value="Full-time">Full-time</Option>
                                <Option value="Part-time">Part-time</Option>

                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Work Experience"
                            name="work_experience"
                            id="work_experience"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please Select"
                                onChange={onFormWorkExperienceChange}
                                defaultValue={workExperience}
                            >
                                {[...Array(22)].map((no, i) => (
                                    <Option value={++i} key={i}> {i} to {++i} Years </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Number of Opening"
                            name="number_of_opening"
                            id="number_of_opening"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please Select"
                                onChange={onFormNumberOfOpeningChange}
                                defaultValue={numberOfOpening}
                            >
                                {[...Array(22)].map((no, i) => (
                                    <Option value={++i} key={i}>{++i}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            id="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Mobile Number"
                            name="mobile_number"
                            id="mobile_number"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Address"
                            name="address"
                            id="address"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                libraries={["drawing", "places"]}
                            >
                                <Autocomplete
                                    onLoad={onAutoCompleteIsLoad}
                                    onPlaceChanged={onAutoCompletePlaceIsChanged}
                                >
                                    <Input value={address}/>
                                </Autocomplete>
                            </LoadScript>
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>

        </Content>
    )
};

export default PageForm;
