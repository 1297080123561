import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Layout, Form, Input } from "antd";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import { errorResponse, successResponse } from "../../../helpers/response";
import url from "../../../../Development.json";
import { Card, CardHeader } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";


const PageForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [id, setId] = useState('');
    const [description, setDescription] = useState('<p>Product <strong>Abc &nbsp;</strong></p>');
    const [clubId, setClubId] = useState('');
    const { clubIds } = useParams();

    const { state } = useLocation()
    const { Content } = Layout;
    const menubar = true;

    const plugins =
        "link image code table textcolor colorpicker fullscreen hr lists";
    const toolbar =
        "fontselect fontsizeselect formatselect | " +
        "bold italic underline strikethrough subscript superscript | " +
        "blockquote removeformat | forecolor backcolor | " +
        "alignleft aligncenter alignright alignjustify | " +
        "indent outdent | numlist bullist | " +
        "link unlink | hr table image | fullscreen code | undo redo";
    const editorRef = useRef(null);
   
    useEffect(() => {
        async function getDataAsync() {
            if (clubIds) {
                await setClubId(clubIds);
            }
            if (state && state.id) {
                await fetchData(state.id)
                await setId(state.id);
            }


        }
        getDataAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubIds]);



    const fetchData = (id) => {
        let idpass = `?id=${id}`;
        Http
            .get(process.env.REACT_APP_BASE_URL + url.club_product_edit_get + idpass)
            .then((response) => {
                let data = response.data.data;
                form.setFieldsValue({
                    name: data.name,
                    price: data.price,
                    description: data.description,
                });
                setDescription(data.description);
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onSubmit = async (data) => {
        setIsLoading(true);
        if (state && state.id) {
            data["id"] = state.id;
        }
        await Http
            .post(
                process.env.REACT_APP_BASE_URL + url.club_product_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
                navigate('/club-product/' + clubId );
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onFinish = (values) => {
        const data = {
            name: values.name,
            price: values.price,
            description: description,
            club_id: clubId,
        }
        onSubmit(data);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleEditorChangeDescription = (value) => {
        setDescription(value);
    };
    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Club Product</Breadcrumb.Item>
                <Breadcrumb.Item> {id ? 'Update' : 'Add'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper">
                <Card title="Club Form" bordered={true} className=''>
                    <CardHeader className="card-header-part">
                        <h5>Club Product {id ? 'Update' : 'Add'}</h5>
                    </CardHeader>

                    <Form form={form}
                        name="basic"
                        labelCol={{
                            span: 3,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className="m-5"
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            id="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Price"
                            name="price"
                            id="price"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Price!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                  
                        <Form.Item
                            label="Description"
                            name="description"
                            id="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Description!',
                                },
                            ]}
                        >
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMAC_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={description}
                                init={{ plugins, toolbar, menubar }}
                                onEditorChange={handleEditorChangeDescription}
                            />
                        </Form.Item>


                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>

        </Content>
    )
};

export default PageForm;
