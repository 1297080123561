import React, { useEffect, useState } from "react";
import { Breadcrumb, Layout, Form, Input } from "antd";
import { Card } from 'antd';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import { errorResponse, successResponse } from "../../../helpers/response";
import url from "../../../../Development.json";

const PageForm = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();
    const { Content } = Layout;

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const fetchData = async () => {
        await Http
            .get(process.env.REACT_APP_BASE_URL + url.setting_edit_get)
            .then((response) => {
                let data = response.data.data;
                form.setFieldsValue({
                    about_content: data.about_content,
                    about_media: data.about_media,
                    about_us: data.about_us,
                    contact_us: data.contact_us,
                    customer_footer_about_us: data.customer_footer_about_us,
                    db_backup_email_id: data.db_backup_email_id,
                    download_brochure: data.download_brochure,
                    email: data.email,
                    facebook_url: data.facebook_url,
                    instagram_url: data.instagram_url,
                    logo: data.logo,
                    name: data.name,
                    phone_number: data.phone_number,
                    site_address: data.site_address,
                    twitter_url: data.twitter_url,
                    youtube_url: data.youtube_url,
                });
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onSubmit = async (data) => {
        setIsLoading(true);
        await Http
            .post(
                process.env.REACT_APP_BASE_URL + url.setting_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };



    const onFinish = (values) => {
        const data = {
            about_content: values.about_content,
            about_media: values.about_media,
            about_us: values.about_us,
            contact_us: values.contact_us,
            customer_footer_about_us: values.customer_footer_about_us,
            db_backup_email_id: values.db_backup_email_id,
            download_brochure: values.download_brochure,
            email: values.email,
            facebook_url: values.facebook_url,
            instagram_url: values.instagram_url,
            logo: values.logo,
            name: values.name,
            phone_number: values.phone_number,
            site_address: values.site_address,
            twitter_url: values.twitter_url,
            youtube_url: values.youtube_url,
        }
        onSubmit(data);
    };


    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Setting</Breadcrumb.Item>
                <Breadcrumb.Item> {'Update'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper center p-5 align-items-center">
                <Card title="Setting Form" bordered={true} className=''>
                    <Form form={form}
                        name="Setting"

                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >

                        <Form.Item
                            label="Name"
                            name="name"
                            id="name"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your name !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Phone Number"
                            name="phone_number"
                            id="phone_number"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your Phone No !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            id="email"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your Email !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item
                            label="Facebook Url"
                            name="facebook_url"
                            id="facebook_url"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your Facebook Url !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Twitter Url"
                            name="twitter_url"
                            id="twitter_url"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your Twitter Url !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Instagram Url"
                            name="instagram_url"
                            id="instagram_url"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your Instagram Url !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item
                            label="Footer Text"
                            name="customer_footer_about_us"
                            id="customer_footer_about_us"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your Footer Text !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="About Us"
                            name="about_us"
                            id="about_us"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your About Us Text !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Contact Us"
                            name="contact_us"
                            id="contact_us"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your Contact Us Text !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Download Brochure Link"
                            name="download_brochure"
                            id="download_brochure"
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your Download Brochure Link !`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>




                        <Form.Item
                            wrapperCol={{
                                offset: 2,
                                span: 10,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>

                    </Form>
                </Card>
            </div>
        </Content>
    )
};

export default PageForm;
