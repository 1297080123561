import React, { useEffect, useState } from "react";
import { Breadcrumb, Layout, Form, Input, Table } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import { errorResponse, successResponse } from "../../../helpers/response";
import url from "../../../../Development.json";

const PageForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState('');
    const [dataCollectionPermissions, setDataCollectionPermissions] = useState([]);
    const [permissionsGetSelect, setPermissionsGetSelect] = useState([]);

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { state } = useLocation()
    const { Content } = Layout;

    useEffect(() => {
        if (state && state.id) {
            fetchData(state.id)
            setId(state.id);
        }
        PermissionsData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = (id) => {
        let idpass = `?id=${id}`;
        Http
            .get(process.env.REACT_APP_BASE_URL + url.role_edit + idpass)
            .then((response) => {
                let data = response.data.data;
                form.setFieldsValue({
                    name: data.name,
                });
                setPermissionsGetSelect(JSON.parse(data.permissionName));
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        if (state && state.id) {
            data["id"] = state.id;
        }
        Http
            .post(
                process.env.REACT_APP_BASE_URL + url.role_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
                navigate('/role');
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const PermissionsData = () => {
        Http
            .get(process.env.REACT_APP_BASE_URL + url.permissions_get)
            .then((response) => {
                let permissionData = response.data.data;
                let dataCollection = [];
                for (var i = 0; i < permissionData.length; i++) {
                    const element = permissionData[i];
                    if (!dataCollection[element.parent]) {
                        dataCollection[element.parent] = [];
                    }
                    dataCollection[element.parent].push(element);
                }
                setDataCollectionPermissions([dataCollection])
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onFinish = (values) => {
        const data = {
            name: values.name,
            permissionsGet: permissionsGetSelect,
            id: id,
        }
        onSubmit(data);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    let dataSource = [];
    let perAllName = [];
    if (dataCollectionPermissions) {
        // eslint-disable-next-line array-callback-return
        dataCollectionPermissions.map((elem, index) => {
            const element = elem;
            var keys = Object.keys(element);
            keys.forEach(key => {
                let tdData = {}
                // eslint-disable-next-line array-callback-return
                elem[key].map((elemsmall, elindex) => {
                    let valueName = elemsmall.module;
                    if (elemsmall.position !== 0) {
                        perAllName.push(elemsmall.name);
                    }
                    let value = (
                        <div className="custom-control custom-switch">
                            <input type="checkbox"
                                className={"select custom-control-input " + elemsmall.parent}
                                name={`permission_name_` + elemsmall.name}
                                id={`permission_name_` + elemsmall.name}
                                onChange={e => handleChangeRole(e, elemsmall.name)}
                                defaultChecked={permissionsGetSelect.includes(elemsmall.name) ? true : false}
                                value={permissionsGetSelect.includes(elemsmall.name) ? true : false}
                                data-value={elemsmall.name}
                                data-position={elemsmall.position}
                                data-parent={elemsmall.parent}
                            />

                            <label className="custom-control-label" htmlFor={`permission_name_` + elemsmall.name}></label>
                        </div>
                    );
                    if (elemsmall.position === 0) {
                        tdData.name = valueName
                    }
                    if (elemsmall.position === 1) {
                        tdData.view = value
                    }
                    if (elemsmall.position === 2) {
                        tdData.create = value
                    }
                    if (elemsmall.position === 3) {
                        tdData.store = value
                    }
                    if (elemsmall.position === 4) {
                        tdData.edit = value
                    }
                    if (elemsmall.position === 5) {
                        tdData.active_in_active = value
                    }
                    if (elemsmall.position === 6) {
                        tdData.delete = value
                    }
                    if (elemsmall.position === 7) {
                        tdData.other = value
                    }
                    tdData.key = elemsmall.name + '_' + elindex
                })
                dataSource.push(tdData);
            })
        })

        //   setisState(permissionsGetSelect.length === perAllName.length);
    }

    const onCheckAllChange = () => {
        var data = document.getElementsByClassName('select');
        let val
        if (document.getElementById("multi_checkbox").checked) {
            // setValue('permission_name', 0);
            val = true;
        } else {
            val = false;
        }
        let arrVAl = [];

        for (var index = 1; index < data.length; ++index) {
            var value = data[index].dataset.value;
            document.getElementById(`permission_name_` + value).checked = val;
            if (val === true) {
                arrVAl.push(value);
            }
        }
        setPermissionsGetSelect(arrVAl);
    };

    const checkBocSelecteAuto = (idName, perName) => {
        document.getElementById(idName).checked = true;
        document.getElementById(idName).disabled = true;
        if (Object.values(permissionsGetSelect).indexOf(perName) === -1) {
            setPermissionsGetSelect(oldArray => [...oldArray, perName]);
        }
    }

    const checkBocSelecteRemoveAuto = (idName, perName) => {
        if (Object.values(permissionsGetSelect).indexOf(idName) === -1) {

            document.getElementById(idName).disabled = false;
            document.getElementById(idName).checked = false;
            var index = permissionsGetSelect.indexOf(perName);
            permissionsGetSelect.splice(index, 1);
            if (index > -1) {
                setPermissionsGetSelect(permissionsGetSelect);
            }
        }
    }

    const handleChangeRole = (e, name) => {
        // setValue('permission_name', 0);
        document.getElementById(`permission_name_` + name).checked = e.target.checked;
        let attr = document.getElementById(`permission_name_` + name);
        let idName = attr.getAttribute("data-value");
        // let parent = attr.getAttribute("data-parent");
        if (e.target.checked) {
            if (Object.values(permissionsGetSelect).indexOf(idName) === -1) {
                setPermissionsGetSelect(oldArray => [...oldArray, idName]);
            }
            checkBocSelecteAuto(`permission_name_` + attr.getAttribute("data-parent") + '_view', attr.getAttribute("data-parent") + '_view')
            if (attr.getAttribute("data-position") === 3) {
                checkBocSelecteAuto(`permission_name_` + attr.getAttribute("data-parent") + '_create', attr.getAttribute("data-parent") + '_create');
            }
            if (attr.getAttribute("data-position") === 4) {
                checkBocSelecteAuto(`permission_name_` + attr.getAttribute("data-parent") + '_create', attr.getAttribute("data-parent") + '_create');
                checkBocSelecteAuto(`permission_name_` + attr.getAttribute("data-parent") + '_store', attr.getAttribute("data-parent") + '_store');
            }
        } else {

            // var cusid_ele = document.getElementsByClassName(parent);
            // let dontAllowView = 1
            // for (var i = 0; i < cusid_ele.length; ++i) {
            //     var item = cusid_ele[i];
            //     // if (cusid_ele[i].dataset.position !== '1') {

            //         if(item.value == true){
            //             dontAllowView++;
            //         }                    
            //     // }
            // }


            if (Object.values(permissionsGetSelect).indexOf(idName) > -1) {
                document.getElementById("multi_checkbox").checked = false;
                if (attr.getAttribute("data-position") === 2) {
                    // if (dontAllowView) {                        
                    //     checkBocSelecteRemoveAuto(`permission_name_` + attr.getAttribute("data-parent") + '_view', attr.getAttribute("data-parent") + '_view')
                    // }
                }
                if (attr.getAttribute("data-position") === 3) {
                    // if (dontAllowView) {
                    //     checkBocSelecteRemoveAuto(`permission_name_` + attr.getAttribute("data-parent") + '_view', attr.getAttribute("data-parent") + '_view')
                    // }
                    checkBocSelecteRemoveAuto(`permission_name_` + attr.getAttribute("data-parent") + '_create', attr.getAttribute("data-parent") + '_create')
                }
                if (attr.getAttribute("data-position") === 4) {
                    checkBocSelecteRemoveAuto(`permission_name_` + attr.getAttribute("data-parent") + '_store', attr.getAttribute("data-parent") + '_store')
                    checkBocSelecteRemoveAuto(`permission_name_` + attr.getAttribute("data-parent") + '_create', attr.getAttribute("data-parent") + '_create')
                    // if (dontAllowView) {
                    //     checkBocSelecteRemoveAuto(`permission_name_` + attr.getAttribute("data-parent") + '_view', attr.getAttribute("data-parent") + '_view')

                    // }

                }
                if (attr.getAttribute("data-position") === 5) {
                    // if (dontAllowView) {
                    //     checkBocSelecteRemoveAuto(`permission_name_` + attr.getAttribute("data-parent") + '_view', attr.getAttribute("data-parent") + '_view')

                    // }
                }
                if (attr.getAttribute("data-position") === 6) {
                    // if (dontAllowView) {

                    //     checkBocSelecteRemoveAuto(`permission_name_` + attr.getAttribute("data-parent") + '_view', attr.getAttribute("data-parent") + '_view')
                    // }
                }
                setPermissionsGetSelect(permissionsGetSelect.filter(item => item !== idName));
            }
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: 'view',
        },
        {
            title: 'Create',
            dataIndex: 'create',
            key: 'create',
        },
        {
            title: 'Store',
            dataIndex: 'store',
            key: 'store',
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
        },
        {
            title: 'Active In-Active',
            dataIndex: 'active_in_active',
            key: 'active_in_active',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
        },
        {
            title: 'Other',
            dataIndex: 'other',
            key: 'other',
        },

    ];


    return (

        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Roles Pages</Breadcrumb.Item>
                <Breadcrumb.Item> {id ? 'Update' : 'Add'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper center p-5 align-items-center">
                <Card title="Roles Pages Form" bordered={true} className=''>
                    <Form form={form}
                        name="role-pages"
                        labelCol={{
                            span: 2,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            id="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Permissions"
                            name="permissions"
                            id="permissions"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input your Permissions!',
                                },
                            ]}
                        >
                            <div className="custom-control custom-checkbox">
                                <input className="custom-control-input select" type="checkbox"
                                    name="multi_checkbox"
                                    id="multi_checkbox"
                                    defaultChecked={permissionsGetSelect.length === perAllName.length}
                                    onChange={onCheckAllChange}
                                />
                                <label className="custom-control-label" htmlFor="multi_checkbox">Select All</label>
                            </div>
                            <br></br>
                            <div className="permissionsTable">
                                <div className="checkboxgroup-scroll">
                                    <Table dataSource={dataSource} columns={columns} pagination={false} scroll={{ y: 300 }} />
                                </div>
                            </div>
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 2,
                                span: 10,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </Content>

    )
};

export default PageForm;
