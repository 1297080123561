import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, CardHeader, Table as TableModal } from "reactstrap";
import { Table, Modal, Breadcrumb, Layout, Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faPencilAlt, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Http from '../../../security/Http';
import { errorResponse, successResponse } from "../../../helpers/response";
import url from "../../../../Development.json";

import { Tab, Tabs,Badge } from 'react-bootstrap';

const Index = () => {
    const [dataTableData, setDataTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [visible, setVisible] = useState(false);
    const [viewModalText, setviewModalText] = useState();
    const [status, setStatus] = useState('0');

    const navigate = useNavigate();
    const { Content } = Layout;
    let currentFilterText = '';

    useEffect(() => {
        getData(); // page load data Get 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  Start here crud related function
    useEffect(() => {
        async function getDataUseEffect() {
            await getData()
        }
        getDataUseEffect()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const getData = async (page = 1, perPage = 10, sortField = 'created_at', sortDirection = 'desc') => {
        let options = `?page=${page}&per_page=${perPage}&delay=1&sort_direction=${sortDirection}&sort_field=${sortField}&search=${currentFilterText}&status=${status}`;
        setDataTableData([])
        setTotalRows(0)
        await Http.get(process.env.REACT_APP_BASE_URL + url.proposal_get + options)
            .then((response) => {
                setLoading(false);
                setDataTableData(response.data.data.docs);
                setTotalRows(response.data.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    }

    const columnsAnt = [
        {
            title: 'Title',
            dataIndex: 'title',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Type',
            dataIndex: 'type',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },

        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Turnover Pa',
            dataIndex: 'turnover_pa',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'File Upload',
            dataIndex: 'file_upload',
            render: (text, row) => {
                return (
                    <a href={row.file_upload} rel="noreferrer" target="_blank" className="btn_Black">
                        Download Proposal
                    </a>
                );
            },
        },
        // {
        //     title: 'Is Active',
        //     dataIndex: 'is_active',
        //     sorter: true,
        //     render: (text, row) => {
        //         return (
        //             <span className={`btn btn-sm   ${row.is_active === true ? "btn-success" : "btn-danger"}`}>
        //                 {
        //                     row.is_active === true ? "Yes" : "No"
        //                 }
        //             </span>
        //         );
        //     },
        //     filters: [
        //         {
        //             text: 'Yes',
        //             value: true,
        //         },
        //         {
        //             text: 'No',
        //             value: false,
        //         },
        //     ],
        //     filterMode: 'tree',
        //     filterSearch: true,
        //     onFilter: (value, record) => record.status === value ?? record.status,
        //     sortDirections: ["ascend", "descend", "ascend"],
        // },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, row) => {
                return (
                    <>
                        <div className='action-btn my-theme-color-button'>

                            {Number(row.status) === 0 ?
                                <>
                                    <Button type="primary" onClick={(e) => changeStatusButtonClick(row._id, 1)}>
                                        Approve
                                    </Button>
                                    <Button type="primary" onClick={(e) => changeStatusButtonClick(row._id, 3)}>
                                        Reject
                                    </Button>
                                </>
                                : ''
                            }

                            <Tooltip title="View">
                                <Button type="primary" onClick={(e) => showRowDataModal(row)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Tooltip>
                            {(Number(row.status) === 0 || Number(row.status) === 2) ?
                                <>
                                    <Tooltip title="Edit">
                                        <Button type="primary" onClick={(id) => { editButtonClick(row._id) }}>
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <Button type="primary" onClick={(id) => { deleteButtonClick(row._id) }} >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    </Tooltip>
                                </>
                                : ''
                            }

                            {(Number(row.status) === 2) ?
                                <span className="text-center">
                                    {(row.is_passed === true) ? <Badge bg="success">Success</Badge> :
                                        <Badge bg="danger">Fail</Badge>
                                    }
                                </span>
                                : ''}


                            {/* <Tooltip title="Change status">
                                <Button type="primary" onClick={(e) => changeStatusButtonClick(row._id)}>
                                    {
                                        row.status === 1 ? <FontAwesomeIcon icon={faToggleOff} /> : <FontAwesomeIcon icon={faToggleOn} />
                                    }
                                </Button>
                            </Tooltip> */}

                        </div>
                    </>
                );
            },
        },
    ];

    const filterComponentHandleChange = (event) => {
        currentFilterText = event.target.value;
        setFilterText(currentFilterText);
        getData();
    }

    const onChange = (pagination, filters, sorter, extra) => {
        getData(pagination.current, pagination.pageSize, sorter.field, sorter.order)
    }

    const deleteButtonClick = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let obj = `?id=${id}`;
                await Http
                    .del(process.env.REACT_APP_BASE_URL + url.proposal_delete + obj)
                    .then((response) => {
                        getData();
                        successResponse(response);
                    })
                    .catch((error) => {
                        if (error.response) {
                            errorResponse(error);
                        }
                    });
            }
        })
    };

    const changeStatusButtonClick = async (id, status) => {
        const obj = {
            id: id,
            status: status,
        };
        await Http.post(process.env.REACT_APP_BASE_URL + url.proposal_change_status, obj)
            .then((response) => {
                successResponse(response);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const editButtonClick = (id) => {
        navigate('/proposal/form', { state: { id: id } });
    };

    const showRowDataModal = (row) => {
        let TableModaldata = (
            <>
                <TableModal striped bordered hover className="cr-table">
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <td>{row._id}</td>
                        </tr>
                        <tr>
                            <th>Title</th>
                            <td>{row.title}</td>
                        </tr>
                        <tr>
                            <th>Type</th>
                            <td>{row.type}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{row.name}</td>
                        </tr>

                        <tr>
                            <th>Amount</th>
                            <td>{row.amount}</td>
                        </tr>
                        <tr>
                            <th>Turnover Pa</th>
                            <td>{row.turnover_pa}</td>
                        </tr>
                        <tr>
                            <th>Start Date</th>
                            <td>{row.start_date}</td>
                        </tr>
                        <tr>
                            <th>End Date</th>
                            <td>{row.end_date}</td>
                        </tr>

                        <tr>
                            <th>File Upload</th>
                            <td>
                                <a href={row.file_upload} rel="noreferrer" target="_blank" className="btn_Black">
                                    Download Proposal
                                </a>
                            </td>
                        </tr>

                        {/* <tr>
                            <th>Is Active</th>
                            <td><span className={`btn btn-sm   ${row.is_active === 1 ? "btn-success" : "btn-danger"}`}>
                                {
                                    row.is_active === 1 ? "Yes" : "No"
                                }
                            </span>
                            </td>
                        </tr> */}

                    </tbody>
                </TableModal>
            </>
        )
        setviewModalText(TableModaldata);
        setVisible(true);
    };

    const handleViewModelCancel = () => {
        setVisible(false);
    };



    //  End here crud related function

    return (
        <Fragment>
            <Content className="site-layout-background">
                <Breadcrumb
                    style={{
                        margin: "16px 0",
                    }}
                >
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Proposal</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-card-view">
                    <Card>
                        <CardHeader className="card-header-part">
                            <h5>Proposal List</h5>
                            <div className="card-header-action ml-3">
                                <div className="d-flex justify-content-end">
                                    <div className="form-group mb-0 mr-3">
                                        <input type="text"
                                            className="form-control"
                                            id="search"
                                            placeholder="Search"
                                            value={filterText}
                                            onChange={(event) => filterComponentHandleChange(event)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="table-part table-style-1">
                                <div className="table-responsive">

                                    <Tabs
                                        defaultActiveKey="0"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                        onSelect={(k) => setStatus(k)}
                                    >
                                        <Tab eventKey="0" title="Pending">
                                            <Table
                                                columns={columnsAnt}
                                                dataSource={dataTableData}
                                                rowKey={"_id"}
                                                loading={loading}
                                                pagination={{
                                                    total: totalRows,
                                                    showSizeChanger: true
                                                }}
                                                onChange={onChange}
                                                exportableProps={{ showColumnPicker: true }}
                                            />
                                        </Tab>
                                        <Tab eventKey="1" title="Live">
                                            <Table
                                                columns={columnsAnt}
                                                dataSource={dataTableData}
                                                rowKey={"_id"}
                                                loading={loading}
                                                pagination={{
                                                    total: totalRows,
                                                    showSizeChanger: true
                                                }}
                                                onChange={onChange}
                                                exportableProps={{ showColumnPicker: true }}
                                            />
                                        </Tab>
                                        <Tab eventKey="2" title="Upcoming">
                                            <Table
                                                columns={columnsAnt}
                                                dataSource={dataTableData}
                                                rowKey={"_id"}
                                                loading={loading}
                                                pagination={{
                                                    total: totalRows,
                                                    showSizeChanger: true
                                                }}
                                                onChange={onChange}
                                                exportableProps={{ showColumnPicker: true }}
                                            />
                                        </Tab>
                                        <Tab eventKey="3" title="Ended">
                                            <Table
                                                columns={columnsAnt}
                                                dataSource={dataTableData}
                                                rowKey={"_id"}
                                                loading={loading}
                                                pagination={{
                                                    total: totalRows,
                                                    showSizeChanger: true
                                                }}
                                                onChange={onChange}
                                                exportableProps={{ showColumnPicker: true }}
                                            />
                                        </Tab>
                                        <Tab eventKey="4" title="Rejected">
                                            <Table
                                                columns={columnsAnt}
                                                dataSource={dataTableData}
                                                rowKey={"_id"}
                                                loading={loading}
                                                pagination={{
                                                    total: totalRows,
                                                    showSizeChanger: true
                                                }}
                                                onChange={onChange}
                                                exportableProps={{ showColumnPicker: true }}
                                            />
                                        </Tab>
                                    </Tabs>



                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </Content>
            <Modal title="Proposal Details" centered footer={''} visible={visible} onCancel={handleViewModelCancel}>
                {viewModalText}
            </Modal>
        </Fragment >
    );
}

export default Index;
