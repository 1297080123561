import React, { useEffect, useState } from "react";
import { Layout, Form, Input, Upload, Button, Image, Breadcrumb } from "antd";
import Http from '../../security/Http'
import url from "../../../Development.json";
import {
    errorResponse,
    successResponse,
    validateMessages,
} from "../../helpers/response";
import ButtonSubmitReset from '../layout/ButtonSubmitReset';
import { Card } from 'antd';
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";


const Profile = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [uploadImage, setUploadImage] = useState();
    const [adminProfile, setAdminProfile] = useState();
    const [id, setId] = useState('');

    const [form] = Form.useForm();
    const { Content } = Layout;

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('profile'))) {
            fetchData(JSON.parse(localStorage.getItem('profile'))._id)
            setId(JSON.parse(localStorage.getItem('profile'))._id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async (id) => {
        let idpass = `?id=${id}`;
        await Http
            .get(process.env.REACT_APP_BASE_URL + url.get_profile + idpass)
            .then((response) => {
                let data = response.data.data;
                form.setFieldsValue({
                    email: data.email,
                    first_name: data.first_name,
                    last_name: data.last_name,

                });
                setAdminProfile(data.profile_photo);
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error)
                }
            });
    };
    const onSubmit = async (data) => {
        setIsLoading(true);
        await Http
            .post(process.env.REACT_APP_BASE_URL + url.update_profile, data)
            .then((response) => {
                let data = response.data.data;

                localStorage.setItem(
                    "profile",
                    JSON.stringify(data)
                );
                setIsLoading(false);
                navigate('/dashboard');
                successResponse(response);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    }
    const onFinish = (values) => {
        const data = {
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            profile_photo: uploadImage,
            id: id,
        }
        onSubmit(data);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChangeImage = (info) => {
        if (info.file.status === 'done') {
            setUploadImage(info.file.response.data.image_url);
        }
    };



    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            <div className="page-card-view">
                <Card title="Profile Form" bordered={true} className='col-md-6 col-xs-12 col-lg-6'>
                    <Form form={form}
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        validateMessages={validateMessages()}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            id="first_name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 7,
                                    max: 15,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            id="last_name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 7,
                                    max: 15,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            id="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Profile Pic"
                            name="profilePic"
                            id="profilePic"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Upload
                                listType="picture"
                                name='files'
                                action={process.env.REACT_APP_BASE_URL + url.image_upload + '?type=1'}
                                multiple={false}
                                maxCount={1}
                                onChange={onChangeImage}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>

                            </Upload>
                            <Image
                                width={100}
                                src={adminProfile}
                            />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>

        </Content>

    )
};

export default Profile;
