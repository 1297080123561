import React from "react";
import { Breadcrumb, Layout } from "antd";

const { Content } = Layout;
const Dashboard = () => (
    // <Layout style={{ padding: "0 24px 24px", }} >

        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            Content
            Content
            Content
            Content
            Content
            Content
        </Content>
    // </Layout>
);

export default Dashboard;
