import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Layout, Form } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import { errorResponse, successResponse } from "../../../helpers/response";
import url from "../../../../Development.json";
import { Editor } from "@tinymce/tinymce-react";

const PageForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [id, setId] = useState('');
    const [answer, setAnswer] = useState('<p>AAA <strong>...&nbsp;</strong></p>');
    const [question, setQuestion] = useState('<p>AAA <strong>...&nbsp;</strong></p>');

    const navigate = useNavigate();
    const { state } = useLocation()
    const { Content } = Layout;

    useEffect(() => {
        if (state && state.id) {
            fetchData(state.id)
            setId(state.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = (id) => {
        let idpass = `?id=${id}`;
        Http
            .get(process.env.REACT_APP_BASE_URL + url.faq_edit_get + idpass)
            .then((response) => {
                let data = response.data.data;
                form.setFieldsValue({
                    question: data.question,
                    answer: data.answer,
                });
                setAnswer(data.answer)
                setQuestion(data.question)
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        if (state && state.id) {
            data["id"] = state.id;
        }
        Http
            .post(
                process.env.REACT_APP_BASE_URL + url.faq_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
                navigate('/faq');
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const handleEditorChangeAnswer = (value) => {
        setAnswer(value);
    };

    const handleEditorChangeQuestion = (value) => {
        setQuestion(value);
    };

    const onFinish = (values) => {
        const data = {
            question: question,
            answer: answer,
            id: id,
        }
        onSubmit(data);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // Start Image File Upload 
    const menubar = true;

    const plugins =
        "link image code table textcolor colorpicker fullscreen hr lists";

    const toolbar =
        "fontselect fontsizeselect formatselect | " +
        "bold italic underline strikethrough subscript superscript | " +
        "blockquote removeformat | forecolor backcolor | " +
        "alignleft aligncenter alignright alignjustify | " +
        "indent outdent | numlist bullist | " +
        "link unlink | hr table image | fullscreen code | undo redo";


    const editorRef = useRef(null);


    // End Image File Upload 

    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Faq</Breadcrumb.Item>
                <Breadcrumb.Item> {id ? 'Update' : 'Add'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper center p-5 align-items-center">
                <Card title="Faq Form" bordered={true} className=''>
                    <Form form={form}
                        name="faq"
                        labelCol={{
                            span: 2,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Answer"
                            name="answer"
                            id="answer"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Answer!',
                                },
                            ]}
                        >

                            <Editor
                                apiKey={process.env.REACT_APP_TINYMAC_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={answer}
                                init={{ plugins, toolbar, menubar }}
                                onEditorChange={handleEditorChangeAnswer}
                            />

                        </Form.Item>


                        <Form.Item
                            label="Question"
                            name="question"
                            id="question"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Question!',
                                },
                            ]}
                        >

                            <Editor
                                apiKey={process.env.REACT_APP_TINYMAC_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={question}
                                init={{ plugins, toolbar, menubar }}
                                onEditorChange={handleEditorChangeQuestion}
                            />

                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 2,
                                span: 10,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </Content>
    )
};

export default PageForm;
