import React, { useState } from "react";
import { Layout, Menu, Image } from "antd";
import { Link } from 'react-router-dom';
import {
    HomeOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import logo from '../../../assets/images/logo.png'

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const { Sider } = Layout;
    return <>
        <Sider className="my-sidermenu-color" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
            style={{
                overflow: 'auto',
                minHeight: '100vh',
                // position: 'fixed',
            }}
        >
            <div className="logo" >
                <Image src={logo} alt='meridiem' />
            </div>
            <Menu className="my-sidermenu-color" mode="inline" openKeys={"sub1"}>
                <Menu.Item key="home" icon={<HomeOutlined />}>
                    <Link className="a-rm-color" to='/dashboard' >
                        Home
                    </Link>
                </Menu.Item>

                <Menu.Item key="users" icon={<UsergroupAddOutlined />}>
                    <Link className="a-rm-color" to='/users' >User</Link>
                </Menu.Item>
                <Menu.Item key="proposal" icon={<UsergroupAddOutlined />}>
                    <Link className="a-rm-color" to='/proposal' >Proposal</Link>
                </Menu.Item>

                <Menu.Item key="club" icon={<UsergroupAddOutlined />}>
                    <Link className="a-rm-color" to='/club' >Club</Link>
                </Menu.Item>
                <Menu.Item key="profile" icon={<UsergroupAddOutlined />}>
                    <Link className="a-rm-color" to='/profile' >Profile</Link>
                </Menu.Item>
                <Menu.Item key="notification" icon={<UsergroupAddOutlined />}>
                    <Link className="a-rm-color" to='/notification' >Notification</Link>
                </Menu.Item>
                <Menu.Item key="job" icon={<UsergroupAddOutlined />}>
                    <Link className="a-rm-color" to='/job' >Job</Link>
                </Menu.Item>


                {/* <Menu.Item key="role" icon={<FolderAddOutlined />}>
                    <Link className="a-rm-color" to='/role' >Roles</Link>
                </Menu.Item> */}

                {/* 

                <Menu.Item key="faq" icon={<QuestionCircleFilled />}>
                    <Link className="a-rm-color" to='/faq' >Faq</Link>
                </Menu.Item> */}
                <Menu.Item key="setting" icon={<SettingOutlined />}>
                    <Link className="a-rm-color" to='/setting' >Setting</Link>
                </Menu.Item>

            </Menu>
        </Sider>
    </>

}
export default Sidebar;