import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import Container from 'react-bootstrap/Container';
import { Card } from 'antd';
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import url from "../../../Development.json";
import Http from '../../security/Http'

import {
    errorResponse,
    validateMessages,
    successResponse,
} from "../../helpers/response";
import { requestForToken } from '../../../firebase';

const Login = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.body.className = 'login-body';
        const isLogin = localStorage.getItem("accessToken") || false;
        if (isLogin) {
            window.location.href = "/dashboard";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        requestForToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data) => {
        setIsLoading(true);
        await Http.post(process.env.REACT_APP_BASE_URL + url.login, data)
            .then((response) => {
                let data = response.data.data;
                localStorage.setItem(
                    "accessToken",
                    data.access_token
                );
                localStorage.setItem(
                    "profile",
                    JSON.stringify(data)
                );
                setIsLoading(false);
                successResponse(response);
                navigate('/dashboard');
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    }
    const onFinish = (values) => {
        const data = {
            email: values.email,
            password: values.password,
            device_token: localStorage.getItem("fcm_device_token"),
        }
        onSubmit(data);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Container>
            <div className="site-card-border-less-wrapper center p-5 align-items-center">
                <Card title="Admin Login" bordered={true} className='login-form'>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        validateMessages={validateMessages()}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            id="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    min: 7,
                                    max: 15,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 5,
                                    max: 15,
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <div className='center text-center'>
                            <Button type="primary" htmlType="submit" shape="round" className='login-button '>
                                {isLoading ? <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                                </>
                                    : ''
                                }
                                {isLoading ? 'loading...' : 'Sign In'}
                            </Button>
                        </div>
                    </Form>
                </Card>
            </div>
        </Container>
    );
};

export default Login;