import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Layout, Form, Input, TimePicker, Upload, Button, Image } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import {
    errorResponse,
    successResponse,
    validateMessages
} from "../../../helpers/response";
import url from "../../../../Development.json";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { Card, CardHeader } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { UploadOutlined } from "@ant-design/icons";
import moment from 'moment';

const PageForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [id, setId] = useState('');
    const [description, setDescription] = useState('<p>Product <strong>Abc &nbsp;</strong></p>');
    const [address, setAddress] = useState("");
    const [autoComplete, setAutoComplete] = useState(null);
    const [openHours, setOpenHours] = useState([]);
    const [openHoursShow, setOpenHoursShow] = useState([]);
    const [uploadImage, setUploadImage] = useState([]);

    const { state } = useLocation()
    const { Content } = Layout;
    const menubar = true;

    const plugins =
        "link image code table textcolor colorpicker fullscreen hr lists";
    const toolbar =
        "fontselect fontsizeselect formatselect | " +
        "bold italic underline strikethrough subscript superscript | " +
        "blockquote removeformat | forecolor backcolor | " +
        "alignleft aligncenter alignright alignjustify | " +
        "indent outdent | numlist bullist | " +
        "link unlink | hr table image | fullscreen code | undo redo";
    const editorRef = useRef(null);

    const onChangeImage = async (info) => {
        if (info.file.status === 'done') {
            await setUploadImage([...uploadImage, info.file.response.data.image_url])
            form.setFieldsValue({
                images: uploadImage,
            });
        }
    };
    const onChangeOpenHour = (date, dateString) => {
        // console.log(time);
        setOpenHours(dateString);
    };

    const onAutoCompleteIsLoad = (inputAutoComplete) => {
        setAutoComplete(inputAutoComplete);
    };
    const onAutoCompletePlaceIsChanged = () => {
        if (autoComplete !== null) {
            form.setFieldsValue({
                address: autoComplete.getPlace().formatted_address,
            });
            setAddress(autoComplete.getPlace().formatted_address);
        }
        else {
            console.log("Autocomplete is not loaded yet");
        }
    };

    useEffect(() => {
        if (state && state.id) {
            fetchData(state.id)
            setId(state.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function getData() {
            await setOpenHoursShow([moment(openHours[0], 'HH:mm:ss'),moment(openHours[1], 'HH:mm:ss')])
            form.setFieldsValue({
                open_hours: openHoursShow,
            });
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openHours]);


    const fetchData = async(id) => {
        let idpass = `?id=${id}`;
       await Http
            .get(process.env.REACT_APP_BASE_URL + url.club_edit_get + idpass)
            .then(async(response) => {
                let data = response.data.data;
    
                form.setFieldsValue({
                    name: data.name,
                    address: data.address,
                    description: data.description,
                    images: data.images,
                });
                setOpenHours(JSON.parse(data.open_hours))
                setAddress(data.address);
                setDescription(data.description);
                setUploadImage(JSON.parse(data.images));
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    if(openHours){

    }
    const onSubmit = async (data) => {
        setIsLoading(true);
        if (state && state.id) {
            data["id"] = state.id;
        }
        await Http
            .post(
                process.env.REACT_APP_BASE_URL + url.club_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
                navigate('/club');
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onFinish = (values) => {
        const data = {
            name: values.name,
            address: address,
            description: description,
            open_hours: openHours,
            images: uploadImage,
        }
        onSubmit(data);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleEditorChangeDescription = (value) => {
        setDescription(value);
    };

    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Club</Breadcrumb.Item>
                <Breadcrumb.Item> {id ? 'Update' : 'Add'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper">
                <Card title="Club Form" bordered={true} className=''>
                    <CardHeader className="card-header-part">
                        <h5>Club {id ? 'Update' : 'Add'}</h5>
                    </CardHeader>

                    <Form form={form}
                        name="basic"
                        labelCol={{
                            span: 3,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        validateMessages={validateMessages()}
                        autoComplete="off"
                        className="m-5"
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            id="name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Address"
                            name="address"
                            id="address"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                libraries={["drawing", "places"]}
                            >
                                <Autocomplete
                                    onLoad={onAutoCompleteIsLoad}
                                    onPlaceChanged={onAutoCompletePlaceIsChanged}
                                >
                                    <Input
                                        value={address}
                                    />
                                </Autocomplete>
                            </LoadScript>
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            id="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMAC_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={description}
                                init={{ plugins, toolbar, menubar }}
                                onEditorChange={handleEditorChangeDescription}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Open Hours"
                            name="open_hours"
                            id="open_hours"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TimePicker.RangePicker value={openHoursShow}  onChange={onChangeOpenHour}   />
                        </Form.Item>
                         
                        <Form.Item
                            label="Images"
                            name="images"
                            id="images"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >

                            <Upload
                                name='files'
                                action={process.env.REACT_APP_BASE_URL + url.image_upload + '?type=2'}
                                listType="picture"
                                maxCount={10}
                                multiple
                                onChange={onChangeImage}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload>
                            {uploadImage.map((image, i) =>
                                <Image
                                    width={70}
                                    height={70}
                                    className="p-1"
                                    src={image}
                                    key={i}
                                />
                            )}
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>

        </Content>
    )
};

export default PageForm;
