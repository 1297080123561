import React from 'react';
// import { Layout } from 'antd';
const FooterPage = () => {
    // const { Footer } = Layout;
    // let date = new Date()
    // date = date.getFullYear()
    return (
        <>
        </>
        // <Footer 
        //     style={{
        //         textAlign: 'center',
        //     }}
        // >
        //     Ant Design ©{date} Created by Ant UED
        // </Footer>
    )

}
export default FooterPage;