import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, CardHeader, Table as TableModal } from "reactstrap";
import { Table, Modal, Breadcrumb, Layout, Button, Tooltip, Typography, Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link } from 'react-router-dom';
import { faPlus, faPencilAlt, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Http from '../../../security/Http';
import { errorResponse, successResponse } from "../../../helpers/response";
import url from "../../../../Development.json";
import parse from 'html-react-parser';


const Index = () => {
    const [dataTableData, setDataTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [visible, setVisible] = useState(false);
    const [viewModalText, setviewModalText] = useState();

    const navigate = useNavigate();
    const { Content } = Layout;
    let currentFilterText = '';

    useEffect(() => {
        getData(); // page load data Get 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  Start here crud related function

    const getData = async (page = 1, perPage = 10, sortField = 'created_at', sortDirection = 'desc') => {
        let options = `?page=${page}&per_page=${perPage}&delay=1&sort_direction=${sortDirection}&sort_field=${sortField}&search=${currentFilterText}`;
        setDataTableData([])
        setTotalRows(0)
        await Http.get(process.env.REACT_APP_BASE_URL + url.club_get + options)
            .then((response) => {
                setLoading(false);
                setDataTableData(response.data.data.docs);
                setTotalRows(response.data.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    }

    const productButtonClick = (id) => {
        navigate('/club-product/' + id);
    };
    const COLUMN_STYLE = { width: 300 };

    const productEventButtonClick = (id) => {
        navigate('/club-event/' + id);
    };
    // const productRatingButtonClick = (id) => {
    //     navigate('/club-rating/' + id);
    // };
    const columnsAnt = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Address',
            dataIndex: 'address',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: true,
            render: (text, row) => {
                return (
                    <Typography.Text ellipsis={true} style={COLUMN_STYLE}>
                        {parse(row.description)}
                    </Typography.Text>
                );
            },
            sortDirections: ["ascend", "descend", "ascend"],
        },


        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, row) => {
                return (
                    <>
                        <div className='action-btn my-theme-color-button'>

                            <Tooltip title="Product Manage">
                                <Button type="primary" onClick={(id) => { productButtonClick(row._id) }}>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Product Event Manage">
                                <Button type="primary" onClick={(e) => { productEventButtonClick(row._id) }}>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Tooltip>
                            {/* <Tooltip title="Product Rating Manage">
                                <Button type="primary" onClick={(e) => { productRatingButtonClick(row._id) }}>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Tooltip> */}

                            <Tooltip title="View">
                                <Button type="primary" onClick={(e) => showRowDataModal(row)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <Button type="primary" onClick={(id) => { editButtonClick(row._id) }}>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Tooltip>

                            {/* <Tooltip title="Change status">
                                <Button type="primary" onClick={(e) => changeStatusButtonClick(row._id)}>
                                    {
                                        row.status === 1 ? <FontAwesomeIcon icon={faToggleOff} /> : <FontAwesomeIcon icon={faToggleOn} />
                                    }
                                </Button>
                            </Tooltip> */}
                            <Tooltip title="Delete">
                                <Button type="primary" onClick={(id) => { deleteButtonClick(row._id) }} >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Tooltip>
                        </div>
                    </>
                );
            },
        },
    ];

    const filterComponentHandleChange = (event) => {
        currentFilterText = event.target.value;
        setFilterText(currentFilterText);
        getData();
    }

    const onChange = (pagination, filters, sorter, extra) => {
        getData(pagination.current, pagination.pageSize, sorter.field, sorter.order)
    }

    const deleteButtonClick = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let obj = `?id=${id}`;
                await Http
                    .del(process.env.REACT_APP_BASE_URL + url.club_delete + obj)
                    .then((response) => {
                        getData();
                        successResponse(response);
                    })
                    .catch((error) => {
                        if (error.response) {
                            errorResponse(error);
                        }
                    });
            }
        })
    };

    // const changeStatusButtonClick = async (id) => {
    //     const obj = {
    //         id: id,
    //     };
    //     await Http.post(process.env.REACT_APP_BASE_URL + url.club_change_status, obj)
    //         .then((response) => {
    //             successResponse(response);
    //             getData();
    //         })
    //         .catch((error) => {
    //             if (error.response) {
    //                 errorResponse(error);
    //             }
    //         });
    // };

    const editButtonClick = (id) => {
        navigate('/club/form', { state: { id: id } });
    };

    const showRowDataModal = (row) => {
        let dateShow = JSON.parse(row.open_hours)
        let img = JSON.parse(row.images)

        let TableModaldata = (
            <>
                <TableModal striped bordered hover className="cr-table">
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <td>{row._id}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{row.name}</td>
                        </tr>
                        <tr>
                            <th>Address</th>
                            <td>{row.address}</td>
                        </tr>
                        <tr>
                            <th>Description</th>
                            <td>{parse(row.description)}</td>
                        </tr>
                        <tr>
                            <th>Image</th>
                            <td>

                                {img.map((image, i) =>
                                    <Image
                                        width={70}
                                        height={70}
                                        className="p-1"
                                        src={image}
                                        key={i}
                                    />
                                )}

                            </td>
                        </tr>
                        <tr>
                            <th>Open Hours</th>
                            <td>
                                {dateShow[0]}
                                {" "}
                                to
                                {" "}
                                {dateShow[1]}
                            </td>
                        </tr>

                    </tbody>
                </TableModal>
            </>
        )
        setviewModalText(TableModaldata);
        setVisible(true);
    };

    const handleViewModelCancel = () => {
        setVisible(false);
    };
    //  End here crud related function



    return (
        <Fragment>
            <Content className="site-layout-background">
                <Breadcrumb
                    style={{
                        margin: "16px 0",
                    }}
                >
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Clubs</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-card-view">
                    <Card>
                        <CardHeader className="card-header-part">
                            <h5>Club List</h5>
                            <div className="card-header-action ml-3">
                                <div className="d-flex justify-content-end">
                                    <div className="form-group mb-0 mr-3">
                                        <input type="text"
                                            className="form-control"
                                            id="search"
                                            placeholder="Search"
                                            value={filterText}
                                            onChange={(event) => filterComponentHandleChange(event)}
                                        />
                                    </div>
                                    <div className="form-group mb-0">
                                        <Link to="/club/form" className="menu-link btn ant-btn-primary my-theme-color-button-fa">
                                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>

                            <div className="table-part table-style-1">

                                <div className="table-responsive">
                                    <Table
                                        columns={columnsAnt}
                                        dataSource={dataTableData}
                                        rowKey={"_id"}
                                        loading={loading}
                                        pagination={{
                                            total: totalRows,
                                            showSizeChanger: true
                                        }}
                                        onChange={onChange}
                                        exportableProps={{ showColumnPicker: true }}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </Content>
            <Modal title="Club Details" centered footer={''} visible={visible} onCancel={handleViewModelCancel}>
                {viewModalText}
            </Modal>
        </Fragment >
    );
}

export default Index;
