import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, CardHeader, Table as TableModal } from "reactstrap";
import { Table, Modal, Breadcrumb, Layout, Button, Tooltip, Image } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faPencilAlt, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Http from '../../../security/Http';
import { errorResponse, successResponse } from "../../../helpers/response";
import url from "../../../../Development.json";


const Index = () => {
    const [dataTableData, setDataTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [visible, setVisible] = useState(false);
    const [viewModalText, setviewModalText] = useState();

    const navigate = useNavigate();
    const { Content } = Layout;
    let currentFilterText = '';

    useEffect(() => {
        getData(); // page load data Get 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  Start here crud related function

    const getData = async (page = 1, perPage = 10, sortField = 'created_at', sortDirection = 'desc') => {
        let options = `?page=${page}&per_page=${perPage}&delay=1&sort_direction=${sortDirection}&sort_field=${sortField}&search=${currentFilterText}`;
        await Http.get(process.env.REACT_APP_BASE_URL + url.user_get + options)
            .then((response) => {
                setLoading(false);
                setDataTableData(response.data.data.docs);
                setTotalRows(response.data.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    }

    const columnsAnt = [
        {
            title: 'First Name',
            dataIndex: 'first_name',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Phone No',
            dataIndex: 'mobile_number',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Invesor Role',
            dataIndex: 'investor_role',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Invesor Name',
            dataIndex: 'proxy_investor_id',
            render: (text, row) => {
                return (
                    (row.userData.first_name) ? row.userData.first_name : '--'
                );
            },
        },

        // {
        //     title: 'User Verify',
        //     dataIndex: 'userVerify',
        //     sorter: true,
        //     render: (text, row) => {
        //         return (
        //             <span className={`btn btn-sm   ${row.userVerify === 1 ? "btn-success" : "btn-danger"}`}>
        //                 {
        //                     row.userVerify === 1 ? "Yes" : "No"
        //                 }
        //             </span>
        //         );
        //     },
        //     filters: [
        //         {
        //             text: 'Yes',
        //             value: 1,
        //         },
        //         {
        //             text: 'No',
        //             value: 0,
        //         },
        //     ],
        //     filterMode: 'tree',
        //     filterSearch: true,
        //     onFilter: (value, record) => record.userVerify === value ?? record.userVerify,
        //     sortDirections: ["ascend", "descend", "ascend"],
        // },
        // {
        //     title: 'Is Active',
        //     dataIndex: 'is_active',
        //     sorter: true,
        //     render: (text, row) => {
        //         return (
        //             <span className={`btn btn-sm   ${row.is_active === true ? "btn-success" : "btn-danger"}`}>
        //                 {
        //                     row.is_active === true ? "Yes" : "No"
        //                 }
        //             </span>
        //         );
        //     },
        //     filters: [
        //         {
        //             text: 'Yes',
        //             value: true,
        //         },
        //         {
        //             text: 'No',
        //             value: false,
        //         },
        //     ],
        //     filterMode: 'tree',
        //     filterSearch: true,
        //     onFilter: (value, record) => record.status === value ?? record.status,
        //     sortDirections: ["ascend", "descend", "ascend"],
        // },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, row) => {
                return (
                    <>
                        <div className='action-btn my-theme-color-button'>
                            <Tooltip title="View">
                                <Button type="primary" onClick={(e) => showRowDataModal(row)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <Button type="primary" onClick={(id) => { editButtonClick(row._id) }}>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Tooltip>

                            {/* <Tooltip title="Change status">
                                <Button type="primary" onClick={(e) => changeStatusButtonClick(row._id)}>
                                    {
                                        row.status === 1 ? <FontAwesomeIcon icon={faToggleOff} /> : <FontAwesomeIcon icon={faToggleOn} />
                                    }
                                </Button>
                            </Tooltip> */}
                            <Tooltip title="Delete">
                                <Button type="primary" onClick={(id) => { deleteButtonClick(row._id) }} >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Tooltip>
                        </div>
                    </>
                );
            },
        },
    ];

    const filterComponentHandleChange = (event) => {
        currentFilterText = event.target.value;
        setFilterText(currentFilterText);
        getData();
    }

    const onChange = (pagination, filters, sorter, extra) => {
        getData(pagination.current, pagination.pageSize, sorter.field, sorter.order)
    }

    const deleteButtonClick = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let obj = `?id=${id}`;
                await Http
                    .del(process.env.REACT_APP_BASE_URL + url.user_delete + obj)
                    .then((response) => {
                        getData();
                        successResponse(response);
                    })
                    .catch((error) => {
                        if (error.response) {
                            errorResponse(error);
                        }
                    });
            }
        })
    };

    // const changeStatusButtonClick = async (id) => {
    //     const obj = {
    //         id: id,
    //     };
    //     await Http.post(process.env.REACT_APP_BASE_URL + url.user_change_status, obj)
    //         .then((response) => {
    //             successResponse(response);
    //             getData();
    //         })
    //         .catch((error) => {
    //             if (error.response) {
    //                 errorResponse(error);
    //             }
    //         });
    // };

    const editButtonClick = (id) => {
        navigate('/users/form', { state: { id: id } });
    };

    const showRowDataModal = (row) => {
        let TableModaldata = (
            <>
                <TableModal striped bordered hover className="cr-table">
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <td>{row._id}</td>
                        </tr>
                        <tr>
                            <th>First Name</th>
                            <td>{row.first_name}</td>
                        </tr>
                        <tr>
                            <th>Last Name</th>
                            <td>{row.last_name}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{row.email}</td>
                        </tr>

                        <tr>
                            <th>Phone No</th>
                            <td>{row.mobile_number}</td>
                        </tr>

                        <tr>
                            <th>Profile Pic</th>
                            <td>
                                <Image
                                    width={100}
                                    src={row.profile_photo}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Bio</th>
                            <td>{row.bio}</td>
                        </tr>
                        <tr>
                            <th>Investor Type</th>
                            <td>{row.investor_type}</td>
                        </tr>
                        <tr>
                            <th>Investor Role</th>
                            <td>{row.investor_role}</td>
                        </tr>
                        <tr>
                            <th>Proxy Investor Id</th>
                            <td>{row.proxy_investor_id}</td>
                        </tr>
                        <tr>
                            <th>Role Id</th>
                            <td>{row.role_id}</td>
                        </tr>
                        <tr>
                            <th>Passbase Id</th>
                            <td>{row.passbase_id}</td>
                        </tr>
                        <tr>
                            <th>Passbase Status</th>
                            <td>{row.passbase_status}</td>
                        </tr>
                        <tr>
                            <th>Member Id</th>
                            <td>{row.member_id}</td>
                        </tr>
                        <tr>
                            <th>Is Active</th>
                            <td><span className={`btn btn-sm   ${row.is_active === 1 ? "btn-success" : "btn-danger"}`}>
                                {
                                    row.is_active === 1 ? "Yes" : "No"
                                }
                            </span>
                            </td>
                        </tr>

                    </tbody>
                </TableModal>
            </>
        )
        setviewModalText(TableModaldata);
        setVisible(true);
    };

    const handleViewModelCancel = () => {
        setVisible(false);
    };
    //  End here crud related function



    return (
        <Fragment>
            <Content className="site-layout-background">
                <Breadcrumb
                    style={{
                        margin: "16px 0",
                    }}
                >
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Users</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-card-view">
                    <Card>
                        <CardHeader className="card-header-part">
                            <h5>User List</h5>
                            <div className="card-header-action ml-3">
                                <div className="d-flex justify-content-end">
                                    <div className="form-group mb-0 mr-3">
                                        <input type="text"
                                            className="form-control"
                                            id="search"
                                            placeholder="Search"
                                            value={filterText}
                                            onChange={(event) => filterComponentHandleChange(event)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>

                            <div className="table-part table-style-1">

                                <div className="table-responsive">
                                    <Table
                                        columns={columnsAnt}
                                        dataSource={dataTableData}
                                        rowKey={"_id"}
                                        loading={loading}
                                        pagination={{
                                            total: totalRows,
                                            showSizeChanger: true
                                        }}
                                        onChange={onChange}
                                        exportableProps={{ showColumnPicker: true }}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </Content>
            <Modal title="User Details" centered footer={''} visible={visible} onCancel={handleViewModelCancel}>
                {viewModalText}
            </Modal>
        </Fragment >
    );
}

export default Index;
