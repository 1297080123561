import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, CardHeader, Table as TableModal } from "reactstrap";
import { Table, Modal, Breadcrumb, Layout, Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Http from '../../../security/Http';
import { errorResponse } from "../../../helpers/response";
import url from "../../../../Development.json";

const Index = () => {
    const [dataTableData, setDataTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [visible, setVisible] = useState(false);
    const [viewModalText, setviewModalText] = useState();

    const { Content } = Layout;
    let currentFilterText = '';

    useEffect(() => {
        getData(); // page load data Get 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  Start here crud related function

    const getData = async (page = 1, perPage = 10, sortField = 'created_at', sortDirection = 'desc') => {
        let options = `?page=${page}&per_page=${perPage}&delay=1&sort_direction=${sortDirection}&sort_field=${sortField}&search=${currentFilterText}`;
        setDataTableData([])
        setTotalRows(0)
        await Http.get(process.env.REACT_APP_BASE_URL + url.notification_get + options)
            .then((response) => {
                setLoading(false);
                setDataTableData(response.data.data.docs);
                setTotalRows(response.data.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    }

    const columnsAnt = [
        {
            title: 'Title',
            dataIndex: 'title',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'message',
            dataIndex: 'message',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Is Read',
            dataIndex: 'is_read',
            sorter: true,
            render: (text, row) => {
                return (
                    <span className={`btn btn-sm   ${row.is_read ? "btn-success" : "btn-danger"}`}>
                        {
                            row.is_read ? "Yes" : "No"
                        }
                    </span>
                )
            },
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'User Name',
            dataIndex: 'user_name',
            sorter: true,
            render: (text, row) => {
                return (row.userData[0]) ? row.userData[0].first_name + '  ' + row.userData[0].last_name : '';
            },
            sortDirections: ["ascend", "descend", "ascend"],
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, row) => {
                return (
                    <>
                        <div className='action-btn my-theme-color-button'>
                            <Tooltip title="View">
                                <Button type="primary" onClick={(e) => showRowDataModal(row)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Tooltip>
                        </div>
                    </>
                );
            },
        },
    ];

    const filterComponentHandleChange = (event) => {
        currentFilterText = event.target.value;
        setFilterText(currentFilterText);
        getData();
    }

    const onChange = (pagination, filters, sorter, extra) => {
        getData(pagination.current, pagination.pageSize, sorter.field, sorter.order)
    }


    const showRowDataModal = (row) => {
        console.log(row)
        let TableModaldata = (
            <>
                <TableModal striped bordered hover className="cr-table">
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <td>{row._id}</td>
                        </tr>
                        <tr>
                            <th>Title</th>
                            <td>{row.title}</td>
                        </tr>
                        <tr>
                            <th>Message</th>
                            <td>{row.message}</td>
                        </tr>
                        <tr>
                            <th>User Name</th>
                            <td> {
                                (row.userData[0]) ? row.userData[0].first_name + '  ' + row.userData[0].last_name : ''}</td>
                        </tr>
                        <tr>
                            <th>Is Read</th>
                            <td>
                                <span className={`btn btn-sm   ${row.is_read ? "btn-success" : "btn-danger"}`}>
                                    {
                                        row.is_read ? "Yes" : "No"
                                    }
                                </span>

                            </td>
                        </tr>


                    </tbody>
                </TableModal>
            </>
        )
        setviewModalText(TableModaldata);
        setVisible(true);
    };

    const handleViewModelCancel = () => {
        setVisible(false);
    };
    //  End here crud related function



    return (
        <Fragment>
            <Content className="site-layout-background">
                <Breadcrumb
                    style={{
                        margin: "16px 0",
                    }}
                >
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Notifications</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-card-view">
                    <Card>
                        <CardHeader className="card-header-part">
                            <h5>Notification List</h5>
                            <div className="card-header-action ml-3">
                                <div className="d-flex justify-content-end">
                                    <div className="form-group mb-0 mr-3">
                                        <input type="text"
                                            className="form-control"
                                            id="search"
                                            placeholder="Search"
                                            value={filterText}
                                            onChange={(event) => filterComponentHandleChange(event)}
                                        />
                                    </div>

                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>

                            <div className="table-part table-style-1">

                                <div className="table-responsive">
                                    <Table
                                        columns={columnsAnt}
                                        dataSource={dataTableData}
                                        rowKey={"_id"}
                                        loading={loading}
                                        pagination={{
                                            total: totalRows,
                                            showSizeChanger: true
                                        }}
                                        onChange={onChange}
                                        exportableProps={{ showColumnPicker: true }}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </Content>
            <Modal title="Notification Details" centered footer={''} visible={visible} onCancel={handleViewModelCancel}>
                {viewModalText}
            </Modal>
        </Fragment >
    );
}

export default Index;
