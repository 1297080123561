import React from 'react';
import { Col, Layout, Row } from 'antd';
import { AppstoreOutlined, LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import profileIamge from '../../../assets/images/dummy-profile-pic.jpg'

const HeaderPage = () => {
    const { Header } = Layout;

    return (
        <Header className="header my-header-color">
            <Row>
                <Col span={12}>

                </Col>
                <Col span={12}
                >
                    <div className='profile-menu ant-layout-header'>
                        <Menu mode="horizontal">
                            <Menu.SubMenu key="profileMenu" title={
                                <img
                                    // width={40}
                                    className="profile-img-icon"
                                    alt=''
                                    src={profileIamge}
                                />}>
                                <Menu.Item key="profile-profile" icon={<ProfileOutlined />}>
                                    <Link className="a-remove-text-decoration" to='/profile'> Profile </Link>
                                </Menu.Item>
                                <Menu.Item key="change-password-profile" icon={<AppstoreOutlined />}>
                                    <Link className="a-remove-text-decoration" to='/change-password'> Change Password </Link>
                                </Menu.Item>
                                <Menu.Item key="logout-profile" icon={<LogoutOutlined />}>
                                    <Link className="a-remove-text-decoration" to='/logout' > Logout </Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    </div>
                </Col>
            </Row>
        </Header>
    )

}
export default HeaderPage;