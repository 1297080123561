import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, CardHeader, Table as TableModal } from "reactstrap";
import { Table, Modal, Breadcrumb, Layout, Button, Tooltip, Typography ,Image} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { faPlus, faPencilAlt, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Http from '../../../security/Http';
import { errorResponse, successResponse } from "../../../helpers/response";
import url from "../../../../Development.json";
import parse from 'html-react-parser';

const Index = () => {
    const [dataTableData, setDataTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [visible, setVisible] = useState(false);
    const [viewModalText, setviewModalText] = useState();
    const [clubId, setClubId] = useState('');
    const { clubIds } = useParams();
    const navigate = useNavigate();
    const { Content } = Layout;
    let currentFilterText = '';

    useEffect(() => {
        async function getDataAsync() {
            if (clubIds) {
                await setClubId(clubIds);
                await getData(); // page load data Get
            }
        }
        getDataAsync()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubIds]);

    //  Start here crud related function

    const getData = async (page = 1, perPage = 10, sortField = 'created_at', sortDirection = 'desc') => {
        let options = `?page=${page}&per_page=${perPage}&delay=1&sort_direction=${sortDirection}&sort_field=${sortField}&search=${currentFilterText}&clubId=${clubIds}`;
        setDataTableData([])
        setTotalRows(0)
        await Http.get(process.env.REACT_APP_BASE_URL + url.club_product_get + options)
            .then((response) => {
                setLoading(false);
                setDataTableData(response.data.data.docs);
                setTotalRows(response.data.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    }
    const COLUMN_STYLE = { width: 300 };
   
    const columnsAnt = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Price',
            dataIndex: 'price',
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: true,
            render: (text, row) => {
                return (
                    <Typography.Text ellipsis={true} style={COLUMN_STYLE}>
                        {parse(row.description)}
                    </Typography.Text>
                );
            },
            sortDirections: ["ascend", "descend", "ascend"],
        },


        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, row) => {
                return (
                    <>
                        <div className='action-btn my-theme-color-button'>
                           
                            <Tooltip title="View">
                                <Button type="primary" onClick={(e) => showRowDataModal(row)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <Button type="primary" onClick={(e) => { editButtonClick(row._id) }}>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Button type="primary" onClick={(e) => { deleteButtonClick(row._id) }} >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Tooltip>
                        </div>
                    </>
                );
            },
        },
    ];

    const filterComponentHandleChange = (event) => {
        currentFilterText = event.target.value;
        setFilterText(currentFilterText);
        getData();
    }

    const onChange = (pagination, filters, sorter, extra) => {
        getData(pagination.current, pagination.pageSize, sorter.field, sorter.order)
    }

    const deleteButtonClick = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let obj = `?id=${id}`;
                await Http
                    .del(process.env.REACT_APP_BASE_URL + url.club_product_delete + obj)
                    .then((response) => {
                        getData();
                        successResponse(response);
                    })
                    .catch((error) => {
                        if (error.response) {
                            errorResponse(error);
                        }
                    });
            }
        })
    };


    const editButtonClick = (id) => {
        navigate('/club-product/form/' + clubIds , { state: { id: id } });
    };

    const showRowDataModal = (row) => {
        let img = JSON.parse(row.images)

        let TableModaldata = (
            <>
                <TableModal striped bordered hover className="cr-table">
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <td>{row._id}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{row.name}</td>
                        </tr>
                        <tr>
                            <th>Price</th>
                            <td>{row.price}</td>
                        </tr>
                        <tr>
                            <th>Old Price</th>
                            <td>{row.old_price}</td>
                        </tr>
                        <tr>
                            <th>Coin</th>
                            <td>{row.coin}</td>
                        </tr>
                        <tr>
                            <th>Type</th>
                            <td>{row.type}</td>
                        </tr>
                        <tr>
                            <th>Description</th>
                            <td>{parse(row.description)}</td>
                        </tr>
                        <tr>
                            <th>Image</th>
                            <td>

                                {img.map((image, i) =>
                                    <Image
                                        width={70}
                                        height={70}
                                        className="p-1"
                                        src={image}
                                        key={i}
                                    />
                                )}

                            </td>
                        </tr>
                    </tbody>
                </TableModal>
            </>
        )
        setviewModalText(TableModaldata);
        setVisible(true);
    };

    const handleViewModelCancel = () => {
        setVisible(false);
    };
    //  End here crud related function



    return (
        <Fragment>
            <Content className="site-layout-background">
                <Breadcrumb
                    style={{
                        margin: "16px 0",
                    }}
                >
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Club Products</Breadcrumb.Item>
                </Breadcrumb>
                <div className="page-card-view">
                    <Card>
                        <CardHeader className="card-header-part">
                            <h5>Club Product List</h5>
                            <div className="card-header-action ml-3">
                                <div className="d-flex justify-content-end">
                                    <div className="form-group mb-0 mr-3">
                                        <input type="text"
                                            className="form-control"
                                            id="search"
                                            placeholder="Search"
                                            value={filterText}
                                            onChange={(event) => filterComponentHandleChange(event)}
                                        />
                                    </div>
                                    <div className="form-group mb-0">
                                        <Link to={{
                                            pathname: "/club-product/form/" + clubId,
                                        }}
                                            className="menu-link btn ant-btn-primary my-theme-color-button-fa">
                                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>

                            <div className="table-part table-style-1">

                                <div className="table-responsive">
                                    <Table
                                        columns={columnsAnt}
                                        dataSource={dataTableData}
                                        rowKey={"_id"}
                                        loading={loading}
                                        pagination={{
                                            total: totalRows,
                                            showSizeChanger: true
                                        }}
                                        onChange={onChange}
                                        exportableProps={{ showColumnPicker: true }}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </Content>
            <Modal title="Club Product Details" centered footer={''} visible={visible} onCancel={handleViewModelCancel}>
                {viewModalText}
            </Modal>
        </Fragment >
    );
}

export default Index;
