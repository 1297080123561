
import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Layout, Form, Input, Radio } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonSubmitReset from '../../layout/ButtonSubmitReset';
import Http from '../../../security/Http';
import { errorResponse, successResponse, validateMessages } from "../../../helpers/response";
import url from "../../../../Development.json";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { Card, CardHeader } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";


const PageForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [id, setId] = useState('');
    const [address, setAddress] = useState("");
    const [uploadImage, setUploadImage] = useState([]);
    const [gender, setGender] = useState('');
    const [autoComplete, setAutoComplete] = useState(null);
    const [description, setDescription] = useState('<p>Product <strong>Abc &nbsp;</strong></p>');
    const { state } = useLocation()
    const { Content } = Layout;
    const menubar = true;

    const plugins =
        "link image code table textcolor colorpicker fullscreen hr lists";

    const toolbar =
        "fontselect fontsizeselect formatselect | " +
        "bold italic underline strikethrough subscript superscript | " +
        "blockquote removeformat | forecolor backcolor | " +
        "alignleft aligncenter alignright alignjustify | " +
        "indent outdent | numlist bullist | " +
        "link unlink | hr table image | fullscreen code | undo redo";


    const editorRef = useRef(null);
    const onAutoCompleteIsLoad = (inputAutoComplete) => {
        setAutoComplete(inputAutoComplete);
    };
    const handleChangeGender = (e) => {
        setGender(e.target.value)
    }

    const onAutoCompletePlaceIsChanged = () => {
        if (autoComplete !== null) {
            setAddress(autoComplete.getPlace().formatted_address);
        }
        else {
            console.log("Autocomplete is not loaded yet");
        }
    };

    const hendleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    useEffect(() => {
        if (state && state.id) {
            fetchData(state.id)
            setId(state.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditorChangeDescription = (value) => {
        setDescription(value);
    };
    const fetchData = (id) => {
        let idpass = `?id=${id}`;
        Http
            .get(process.env.REACT_APP_BASE_URL + url.profile_edit_get + idpass)
            .then((response) => {
                let data = response.data.data;
                console.log(data)
                form.setFieldsValue({
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    phone_no: data.phone_no,
                    address: data.address,
                    experience: data.experience,
                    designation: data.designation,
                    current_company: data.current_company,
                    location: data.location,
                    skills: data.skills,
                    gender: data.gender,
                    description: data.description,
                });
                setAddress(data.address);
                setDescription(data.description);
                setUploadImage(data.resume);
                setGender(data.gender)
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onSubmit = async (data) => {
        setIsLoading(true);
        if (state && state.id) {
            data["id"] = state.id;
        }
        await Http
            .post(
                process.env.REACT_APP_BASE_URL + url.profile_store,
                data
            )
            .then((response) => {
                setIsLoading(false);
                successResponse(response);
                navigate('/profile');
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const onFinish = (values) => {
        const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone_no: values.phone_no,
            address: address,
            experience: values.experience,
            designation: values.designation,
            current_company: values.current_company,
            location: values.location,
            skills: values.skills,
            gender: gender,
            description: description,
            resume: uploadImage,
        }
        onSubmit(data);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Content className="site-layout-background">
            <Breadcrumb
                style={{
                    margin: "16px 0",
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Profile</Breadcrumb.Item>
                <Breadcrumb.Item> {id ? 'Update' : 'Add'} </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-card-border-less-wrapper">
                <Card title="Profile Form" bordered={true} className=''>
                    <CardHeader className="card-header-part">
                        <h5>Profile {id ? 'Update' : 'Add'}</h5>
                    </CardHeader>

                    <Form form={form}
                        name="basic"
                        labelCol={{
                            span: 3,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        validateMessages={validateMessages()}
                        autoComplete="off"
                        className="m-5"
                    >
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            id="first_name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            id="last_name"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            id="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    min: 3,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Phone No"
                            name="phone_no"
                            id="phone_no"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                    min: 7,
                                    max: 15,
                                },
                            ]}
                        >
                            <Input  />
                        </Form.Item>



                        <Form.Item
                            label="Address"
                            name="address"
                            id="address"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                libraries={["drawing", "places"]}
                            >
                                <Autocomplete
                                    onLoad={onAutoCompleteIsLoad}
                                    onPlaceChanged={onAutoCompletePlaceIsChanged}
                                >
                                    <Input
                                        value={address}
                                        onChange={(e) => hendleAddressChange()}
                                    />
                                </Autocomplete>
                            </LoadScript>
                        </Form.Item>

                        <Form.Item
                            label="Gender"
                            name="gender"
                            id="gender"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                },
                            ]}
                        >
                            <Radio.Group value={gender} onChange={handleChangeGender} className="w-100" name="gender" id="gender">
                                <Radio value={"Male"}>  Male </Radio>
                                <Radio value={"Female"}>  Female </Radio>
                                <Radio value={"Other"}>  Other </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Experience"
                            name="experience"
                            id="experience"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Current Designation"
                            name="designation"
                            id="designation"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Current Company"
                            name="current_company"
                            id="current_company"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Current Location"
                            name="location"
                            id="location"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Skills"
                            name="skills"
                            id="skills"
                            rules={[
                                {
                                    required: true,
                                    type: 'string',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            id="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMAC_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={description}
                                init={{ plugins, toolbar, menubar }}
                                onEditorChange={handleEditorChangeDescription}
                            />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <ButtonSubmitReset isLoading={isLoading} onReset={onReset} />
                        </Form.Item>
                    </Form>
                </Card>
            </div>

        </Content>
    )
};

export default PageForm;
