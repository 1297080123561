import React from "react";
import { useEffect } from 'react';
import { Layout } from "antd";
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import NotificationFcm from './NotificationFcm';
import { Outlet } from 'react-router-dom';
// import url from "../../../Development.json";
// import axios from 'axios';
// import FaviconImg from '../../../assets/favicon.png';


const Main = (props) => {

    const {ptitle} = props;
    // const getFaviconEl = () => {
    //     return document.getElementById("favicon");
    // }
    useEffect(() => {
        var value = localStorage.getItem('theme-color')
        const settingColorThemeCollection = document.getElementsByClassName("my-theme-color");
        for (let i = 0; i < settingColorThemeCollection.length; i++) {
            settingColorThemeCollection[i].classList.add(value)
        }

        const muButtonsettingColorThemeCollection = document.getElementsByClassName("my-theme-color-button");
        for (let i = 0; i < muButtonsettingColorThemeCollection.length; i++) {
            muButtonsettingColorThemeCollection[i].classList.add(value)
        }

    }, []);

    
    useEffect(() => {
        document.title = process.env.REACT_APP_APP_NAME + ' ' + ptitle;
        // const favicon = getFaviconEl(); // Accessing favicon element
        // favicon.href = FaviconImg;
    }, [ptitle]);


    useEffect(() => {
        const isLogin = localStorage.getItem("accessToken") || false;
        if (!isLogin) {
            window.location.href = "/login";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <NotificationFcm />
            <Layout
                style={{
                    minHeight: '100vh',
                }}>
                <Sidebar />
                <Layout>
                    <Header />
                    <Outlet />
                    <Footer />
                </Layout>
            </Layout>
        </>
    );
}

export default Main;
